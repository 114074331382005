import { gql } from '@apollo/client';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

import { TDeleteFleetDeviceResponse } from './types';

const DELETE_FLEET_DEVICE_MUTATION = gql`
  mutation DeleteFleetDevice($fleetDeviceId: ID!) {
    deleteFleetDevice(fleetDeviceId: $fleetDeviceId) {
      status
      message
    }
  }
`;

export const deleteFleetDevice = async (launchDarkly: TLaunchDarklySet, fleetDeviceId: string) => {
  const variables = { fleetDeviceId };
  const response = await client(launchDarkly).mutate<
    TQueryResponse<'deleteFleetDevice', TDeleteFleetDeviceResponse>,
    unknown
  >({
    mutation: DELETE_FLEET_DEVICE_MUTATION,
    variables,
  });

  if (!response.data) {
    throw new Error('No data returned from server');
  }

  return response.data.deleteFleetDevice;
};
