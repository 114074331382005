import React from 'react';
import { Helmet } from 'react-helmet';

import { Favicon } from '@agco-onedigital/ecosystem-lib-web-components';

import { IProps } from './types';

const HeadProvider = ({ dynatraceUrl, brand }: IProps) => {
  if (process.env.NODE_ENV !== 'development') {
    console.log('app version: ', process.env.REACT_APP_VERSION);
  }
  return (
    <>
      <Favicon brand={brand} />
      <Helmet>
        {dynatraceUrl && <script type="text/javascript" src={dynatraceUrl} />}
        <link href={`/fonts/${brand}/index.css`} rel="stylesheet" />
        <link href={`/fonts/${brand}/icomoon.css`} rel="stylesheet" />
        <link href="/fonts/google/index.css" rel="stylesheet" />
      </Helmet>
    </>
  );
};

export default HeadProvider;
