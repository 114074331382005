import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { TQueryResponse } from '../../../types';
import { client } from '../../graphql';
import { IMachineDetails } from '../../../../store/MachineDetails';

interface DeviceImageUploadRequest {
  filename: string;
  deviceId: string;
  encodedFile: string;
}

export const uploadDeviceImage = async (
  launchDarkly: TLaunchDarklySet,
  deviceImageUploadRequest: DeviceImageUploadRequest,
): Promise<IMachineDetails> => {
  const mutation = gql`
    mutation uploadDeviceImage($deviceImageUploadRequest: DeviceImageUploadRequest!) {
      uploadDeviceImage(deviceImageUploadRequest: $deviceImageUploadRequest) {
        id
        type
        brand
      }
    }
  `;

  const result = await client(launchDarkly).mutate<
    TQueryResponse<'uploadDeviceImage', IMachineDetails>,
    { deviceImageUploadRequest: DeviceImageUploadRequest }
  >({
    mutation,
    variables: {
      deviceImageUploadRequest,
    },
  });

  if (!result.data) {
    throw new Error('No data returned from server');
  }

  return result.data.uploadDeviceImage;
};
