import { createAsyncThunk } from '@reduxjs/toolkit';

import { getFleetDevice } from '../../../service/graphql/machine/getFleetDevice';

import { AsyncThunkConfig } from '../../store';

import { IMachineDetails } from '../types';

export const getMachineThunk = createAsyncThunk<IMachineDetails, string, AsyncThunkConfig>(
  'machineDetails/getMachine',
  async (fleetDeviceId, { getState }) => {
    const {
      launchDarkly,
      selectedAccount: { activeAccountId },
    } = getState();

    if (!activeAccountId) {
      throw new Error('No accounts or user found.');
    }

    return getFleetDevice(launchDarkly.data, fleetDeviceId);
  },
);
