import { initializeApp } from 'firebase/app';
import { getToken, Messaging, onMessage } from 'firebase/messaging';
import { CONFIG, VAPIDKEY } from './consts';

initializeApp(CONFIG);

export const requestPermission = (messaging: Messaging) => {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey: VAPIDKEY,
      }).then(currentToken => {
        if (currentToken) {
          /* empty */
        } else {
          console.warn('[ FireBase error: can not get current token ]');
        }
      });
    }
  });
};

export const onMessageListener = (messaging: Messaging) =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      console.log('payload', payload);
      resolve(payload);
    });
  });

export const isPrivateMode = () => {
  try {
    return !navigator.serviceWorker;
  } catch {
    return true;
  }
};
