import { gql } from '@apollo/client';
import { ICategory } from '../../../../store/AddMachine';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getCategories = async (
  launchDarkly: TLaunchDarklySet,
  brandIds: string,
  country?: string,
): Promise<ICategory[]> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'deviceCategories', ICategory[]>>({
    query: gql`
      query {
        deviceCategories (filter: { brandIds: "${brandIds}", countries: ${JSON.stringify(country ? [country] : [])} }) {
          defaultDisplayName
          id
          key
        }
      }
    `,
  });
  return data.deviceCategories;
};
