import React, { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import { useLaunchDarkly } from '../../store/LaunchDarkly';
import { useUserAccount } from '../../store/UserAccount';

import { ModuleContainer } from './styles';

interface IProps {
  src: string;
  id: string;
}

const ModuleLoader = ({ src, id }: IProps) => {
  const { activeAccountId } = useUserAccount(); // TODO: remove and use event sub?
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const { name: brand } = useTheme();
  const { locale } = useIntl();
  const launchDarkly = useLaunchDarkly();
  const env = launchDarkly['config-web-app']?.env;
  const isUpdatedSidebarEnabled = launchDarkly['updated-sidebar-enabled'] ?? false;

  useEffect(() => {
    window.SHARED_MODULE_DATA.brand = brand;
    window.SHARED_MODULE_DATA.locale = locale;
    window.SHARED_MODULE_DATA.env = env;
    window.SHARED_MODULE_DATA.accountId = activeAccountId;
    window.SHARED_MODULE_DATA.isUpdatedSidebar = isUpdatedSidebarEnabled;

    const script = document.createElement('script');
    script.setAttribute('module-id', id);
    script.src = src;
    script.async = true;

    document.body.appendChild(script);
    scriptRef.current = script;

    return () => {
      if (scriptRef.current) {
        scriptRef.current.remove();
      }
    };
  }, [src, id, locale, brand, env, activeAccountId, isUpdatedSidebarEnabled]);

  return <ModuleContainer id={id} />;
};

export default ModuleLoader;
