import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EAuthRoutes } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { LoadingBar } from '@agco-onedigital/ecosystem-lib-web-components';
import { useLaunchDarkly } from '../../store/LaunchDarkly';
import { handleLoginRedirect } from '../../utils/msalConfiguration/utils';
import getMsalInstance from '../../utils/msalConfiguration/msalInstance';

import { EPreAuthRoutes } from './types';

const AuthRouter = () => {
  const navigate = useNavigate();
  const launchDarkly = useLaunchDarkly();
  const location = useLocation();
  useEffect(() => {
    const init = async () => {
      const msalInstance = getMsalInstance(launchDarkly);
      await msalInstance.handleRedirectPromise();
      const account = msalInstance.getActiveAccount();
      if (!account) {
        handleLoginRedirect(launchDarkly);
      } else {
        navigate(EAuthRoutes.DASHBOARD, { replace: true });
      }
    };
    if (location.pathname !== EPreAuthRoutes.REGISTRATION && !process.env.JEST_WORKER_ID) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location]);

  return <LoadingBar />;
};

export default AuthRouter;
