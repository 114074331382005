import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EAddMachineSteps } from '../../pages/AddMachine/types';

import { checkVinDeviceAvailabilityThunk } from './thunks/checkVinDeviceAvailabilityThunk';
import { getCategoriesThunk } from './thunks/getCategoriesThunk';
import { getModelsThunk } from './thunks/getModelsThunk';
import { getModelVariantsThunk } from './thunks/getModelVariantThunk';
import { getSeriesThunk } from './thunks/getSeriesThunk';
import { getSubCategoriesThunk } from './thunks/getSubCategoriesThunk';
import { getBrandsThunk } from './thunks/getBrandsThunk';
import { createDeviceThunk } from './thunks/createDeviceThunk';

import { IAddMachineState, IModel, IModelVariant, IOwnershipVerificationDocument, ISerie } from './types';
import { handleCreateDeviceError, handleCreateDeviceSuccess } from './thunkResultHandlers';

export const initialState: IAddMachineState = {
  data: {},
};

const addMachineSlice = createSlice({
  name: 'addMachine',
  initialState,
  reducers: {
    initAddMachine: state => {
      state.isLoading = false;
      state.vinError = undefined;
      state.data.deviceAvailability = undefined;
    },
    resetAddMachine: () => initialState,
    setAddMachineStep: (state, action: PayloadAction<EAddMachineSteps>) => {
      state.data.currentStep = action.payload;
      delete state.data.documents;
    },
    skipVinStep: state => {
      state.data.isVinSkipped = true;
      state.data.currentStep = EAddMachineSteps.Brand;
    },
    resetSkipVin: state => {
      delete state.data.isVinSkipped;
    },
    setBrandInformation: (
      state,
      action: PayloadAction<Required<Pick<NonNullable<IAddMachineState['data']['nonVinData']>, 'brand'>>>,
    ) => {
      state.data.nonVinData = {
        ...(state.data.nonVinData || {}),
        ...action.payload,
        model: undefined,
        modelVariant: undefined,
        serie: undefined,
        customSerieName: '',
        customModelName: '',
        customModelVariantName: '',
        customSeriesOption: undefined,
        customModelsOption: undefined,
        customModelVariantsOption: undefined,
        yearOfManufacture: undefined,
      };
    },
    setCategoryInformation: (
      state,
      action: PayloadAction<Pick<NonNullable<IAddMachineState['data']['nonVinData']>, 'category' | 'subCategory'>>,
    ) => {
      state.data.nonVinData = {
        ...state.data.nonVinData,
        ...action.payload,
      };
    },
    setMachineInformation: (
      state,
      action: PayloadAction<
        | Required<
            Pick<
              NonNullable<IAddMachineState['data']['nonVinData']>,
              'serie' | 'model' | 'modelVariant' | 'yearOfManufacture'
            >
          >
        | Required<Pick<NonNullable<IAddMachineState['data']['nonVinData']>, 'customModelName' | 'yearOfManufacture'>>
        | Required<
            Pick<
              NonNullable<IAddMachineState['data']['nonVinData']>,
              'customSerieName' | 'customModelName' | 'customModelVariantName' | 'yearOfManufacture'
            >
          >
      >,
    ) => {
      state.data.nonVinData = {
        ...state.data.nonVinData,
        ...action.payload,
      };
    },
    setCustomSeriesOption: (state, action: PayloadAction<ISerie | undefined>) => {
      if (state.data.nonVinData) {
        state.data.nonVinData.customSeriesOption = action.payload;
      }
    },
    setCustomModelsOption: (state, action: PayloadAction<IModel | undefined>) => {
      if (state.data.nonVinData) {
        state.data.nonVinData.customModelsOption = action.payload;
      }
    },
    setCustomModelVariantsOption: (state, action: PayloadAction<IModelVariant | undefined>) => {
      if (state.data.nonVinData) {
        state.data.nonVinData.customModelVariantsOption = action.payload;
      }
    },
    setStartDateAndCondition: (
      state,
      action: PayloadAction<Required<Pick<IAddMachineState['data'], 'startDate' | 'condition'>>>,
    ) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    resetMachineInfoExceptYearOfManufacture: state => {
      state.data.nonVinData = {
        ...state.data.nonVinData,
        model: undefined,
        modelVariant: undefined,
        serie: undefined,
        customSeriesOption: undefined,
        customModelsOption: undefined,
        customModelVariantsOption: undefined,
        customModelName: '',
        customModelVariantName: '',
        customSerieName: '',
        yearOfManufacture: state.data.nonVinData?.yearOfManufacture,
      };
    },
    resetQueriedPimData: state => {
      state.data.series = undefined;
      state.data.models = undefined;
      state.data.modelVariants = undefined;
    },
    resetStartDate: state => {
      state.data.startDate = undefined;
    },
    updateOrAddOwnershipVerificationDocument: (state, action: PayloadAction<IOwnershipVerificationDocument>) => {
      if (!state.data.documents) {
        state.data.documents = [];
      }
      const index = state.data.documents.findIndex(doc => doc.id === action.payload.id);
      if (index === -1) {
        state.data.documents.push(action.payload);
      } else {
        state.data.documents[index] = action.payload;
      }
    },
    removeOwnershipVerificationDocument: (state, action: PayloadAction<string>) => {
      if (state.data.documents) {
        const index = state.data.documents.findIndex(doc => doc.id === action.payload);
        if (index !== -1) {
          state.data.documents.splice(index, 1);
        }
      }
    },
    setSummaryEditedStep: (state, action: PayloadAction<IAddMachineState['data']['summaryEditedStep']>) => {
      state.data.summaryEditedStep = action.payload;
    },
    setIsVerifyVinFlow: (state, action: PayloadAction<boolean>) => {
      state.data.isVerifyVinFlow = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(checkVinDeviceAvailabilityThunk.pending, state => {
      state.isLoading = true;
      state.vinError = undefined;
      state.data.deviceAvailability = undefined;
      delete state.data.isVinSkipped;
    });
    builder.addCase(checkVinDeviceAvailabilityThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      if (!action.payload || action.payload.status === 'UNAVAILABLE') {
        state.vinError = 'not-found';
      } else if (action.payload.status === 'AVAILABLE') {
        state.data.deviceAvailability = action.payload;
        state.data.currentStep = EAddMachineSteps.MachineDetails;
        state.data.isVinSkipped = false;
      } else if (action.payload.status === 'ALREADY_IN_ACCOUNT') {
        state.vinError = 'already-in-account';
      }
    });
    builder.addCase(checkVinDeviceAvailabilityThunk.rejected, state => {
      state.isLoading = false;
      state.vinError = 'service-error';
    });

    builder.addCase(getBrandsThunk.pending, state => {
      state.isBrandLoading = true;
    });
    builder.addCase(getBrandsThunk.rejected, state => {
      // TODO: error handling
      state.isBrandLoading = false;
    });
    builder.addCase(getBrandsThunk.fulfilled, (state, action) => {
      state.isBrandLoading = false;
      state.data.brands = action.payload;
    });

    builder.addCase(getCategoriesThunk.pending, state => {
      state.isCategoryLoading = true;
    });
    builder.addCase(getCategoriesThunk.rejected, state => {
      // TODO: error handling
      state.isCategoryLoading = false;
    });
    builder.addCase(getCategoriesThunk.fulfilled, (state, action) => {
      state.isCategoryLoading = false;
      state.data.categories = action.payload;
    });

    builder.addCase(getSubCategoriesThunk.pending, state => {
      state.isSubCategoryLoading = true;
    });
    builder.addCase(getSubCategoriesThunk.rejected, state => {
      // TODO: error handling
      state.isSubCategoryLoading = false;
    });
    builder.addCase(getSubCategoriesThunk.fulfilled, (state, action) => {
      state.isSubCategoryLoading = false;
      state.data.subCategories = action.payload;
    });

    builder.addCase(getSeriesThunk.pending, state => {
      state.isSeriesLoading = true;
    });
    builder.addCase(getSeriesThunk.rejected, state => {
      // TODO: error handling
      state.isSeriesLoading = false;
    });
    builder.addCase(getSeriesThunk.fulfilled, (state, action) => {
      state.isSeriesLoading = false;
      state.data.series = action.payload;
    });

    builder.addCase(getModelsThunk.pending, state => {
      state.isModelsLoading = true;
    });
    builder.addCase(getModelsThunk.rejected, state => {
      // TODO: error handling
      state.isModelsLoading = false;
    });
    builder.addCase(getModelsThunk.fulfilled, (state, action) => {
      state.isModelsLoading = false;
      state.data.models = action.payload;
    });

    builder.addCase(getModelVariantsThunk.pending, state => {
      state.isModelVariantsLoading = true;
    });
    builder.addCase(getModelVariantsThunk.rejected, state => {
      // TODO: error handling
      state.isModelVariantsLoading = false;
    });
    builder.addCase(getModelVariantsThunk.fulfilled, (state, action) => {
      state.isModelVariantsLoading = false;
      state.data.modelVariants = action.payload;
    });
    builder.addCase(createDeviceThunk.pending, state => {
      state.requestOwnershipError = false;
      state.isLoading = true;
    });
    builder.addCase(createDeviceThunk.rejected, state => {
      handleCreateDeviceError(state);
    });
    builder.addCase(createDeviceThunk.fulfilled, state => {
      handleCreateDeviceSuccess(state);
    });
  },
});

export const {
  initAddMachine,
  resetAddMachine,
  setAddMachineStep,
  skipVinStep,
  resetSkipVin,
  setBrandInformation,
  setCategoryInformation,
  setMachineInformation,
  setCustomSeriesOption,
  setCustomModelsOption,
  setCustomModelVariantsOption,
  setStartDateAndCondition,
  resetMachineInfoExceptYearOfManufacture,
  resetQueriedPimData,
  resetStartDate,
  updateOrAddOwnershipVerificationDocument,
  removeOwnershipVerificationDocument,
  setSummaryEditedStep,
  setIsVerifyVinFlow,
} = addMachineSlice.actions;

const addMachineReducer = addMachineSlice.reducer;

export default addMachineReducer;
