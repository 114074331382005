import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserThunk } from '../User/thunks';
import { TUserAccountState } from './types';
import { getFarmTypesThunk, getBusinessTypesThunk } from './thunks';

const initialState: TUserAccountState = {
  activeAccountId: '',
};

const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    setActiveAccount: (state, action: PayloadAction<string>) => {
      state.activeAccountId = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserThunk.fulfilled, (state, action) => {
      if (!action.payload) {
        state.activeAccountId = '';
        return;
      }
      const lastUsedAccountId = action.payload.lastUsedAccount?.id;
      const { accounts } = action.payload;
      if (lastUsedAccountId && accounts.find(account => account.account.id === lastUsedAccountId)) {
        state.activeAccountId = lastUsedAccountId;
      } else if (!state.activeAccountId || !accounts.find(account => account.account.id === state.activeAccountId)) {
        state.activeAccountId = accounts[0].account.id || '';
      }
    });
    builder.addCase(getUserThunk.rejected, state => {
      state.activeAccountId = '';
    });
    builder.addCase(getFarmTypesThunk.fulfilled, (state, action) => {
      state.farmTypes = action.payload;
    });
    builder.addCase(getBusinessTypesThunk.fulfilled, (state, action) => {
      state.businessTypes = action.payload;
    });
    builder.addCase(getFarmTypesThunk.rejected, state => {
      state.farmTypes = [];
    });
    builder.addCase(getBusinessTypesThunk.rejected, state => {
      state.businessTypes = [];
    });
  },
});

export const { setActiveAccount } = userAccountSlice.actions;
const activeAccountReducer = userAccountSlice.reducer;

export default activeAccountReducer;
