export const getFormattedDate = (dateValue: string, format: 'MMMM YYYY' | 'YYYY') => {
  if (!dateValue) return '';

  const date = new Date(dateValue);
  const year = date.getFullYear();
  const month = date.toLocaleString('en-US', { month: 'long' });

  switch (format) {
    case 'MMMM YYYY':
      return `${month} ${year}`;
    case 'YYYY':
      return year.toString();
    default:
      return date.toString();
  }
};
