import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadDeviceImage } from '../../../service/graphql/machine/uploadDeviceImage';
import { AsyncThunkConfig } from '../../store';
import { IMachineDetails } from '../types';
import { getMachineThunk } from './getMachineThunk';

interface DeviceImageUploadRequest {
  filename: string;
  deviceId: string;
  encodedFile: string;
}

export const uploadDeviceImageThunk = createAsyncThunk<
  IMachineDetails,
  { deviceImageUploadRequest: DeviceImageUploadRequest },
  AsyncThunkConfig
>('machineDetails/uploadDeviceImage', async ({ deviceImageUploadRequest }, { getState, dispatch }) => {
  const {
    launchDarkly,
    selectedAccount: { activeAccountId },
  } = getState();

  if (!activeAccountId) {
    throw new Error('No accounts or user found.');
  }

  const result = await uploadDeviceImage(launchDarkly.data, deviceImageUploadRequest);

  // After uploading the image, fetch the updated machine details
  await dispatch(getMachineThunk(deviceImageUploadRequest.deviceId));

  return result;
});
