import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteFleetDevice } from '../../../service/graphql/machine/deleteFleetDevice';
import { AsyncThunkConfig } from '../../store';

export const deleteMachineThunk = createAsyncThunk<void, string, AsyncThunkConfig>(
  'machineDetails/deleteMachine',
  async (fleetDeviceId, { getState }) => {
    const {
      launchDarkly,
      selectedAccount: { activeAccountId },
    } = getState();

    if (!activeAccountId) {
      throw new Error('No accounts or user found.');
    }

    const response = await deleteFleetDevice(launchDarkly.data, fleetDeviceId);

    if (response.status !== '200 OK') {
      throw new Error(response.message || 'Failed to delete machine.');
    }
  },
);
