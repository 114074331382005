import { languageOptions } from '@agco-onedigital/ecosystem-lib-frontend-utils';

export const DEFAULT_LANGUAGE_OPTION = languageOptions.find(lo => lo.id === 'en_GB') || languageOptions[0];

export const getLanguage = (id?: string) => {
  return languageOptions.find(l => l.id === id) || DEFAULT_LANGUAGE_OPTION;
};

export const getBrowserLanguage = () => {
  const { language, languages } = window.navigator;
  return (
    [language.replace('-', '_'), ...languages.map(l => l.replace('-', '_'))]
      .map(l => languageOptions.find(option => option.id.startsWith(l) || option.locale.startsWith(l.toLowerCase())))
      .find(l => l) ?? DEFAULT_LANGUAGE_OPTION
  ).id;
};
