import React from 'react';
import { GlobalStyle } from '@agco-onedigital/ecosystem-lib-web-components';
import './polyfills';

import Router from './containers/Router';
import Providers from './containers/Providers';
import DataSavingProvider from './components/DataSavingProvider';

const App = () => {
  return (
    <DataSavingProvider>
      <Providers>
        <GlobalStyle />
        <Router />
      </Providers>
    </DataSavingProvider>
  );
};

export default App;
