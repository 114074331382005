import { gql } from '@apollo/client';
import { IUserData } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { TQueryResponse } from '../../../types';
import { client } from '../../graphql';

export const getUserData = async (launchDarkly: TLaunchDarklySet) => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'me', IUserData>>({
    query: gql`
      query {
        me {
          id
          locale
          salutation
          firstName
          lastName
          email
          phone
          mobilePhone
          externalIds {
            salesforceId
          }
          address
          zipCode
          city
          country
          profilePicture
          accounts {
            account {
              id
              accountId
              region
              validationStatus
              name
              officePhone
              businessTypes
              farmTypes
              entityType
              registrationNumber
              country
              city
              address
              zipCode
              externalIds {
                salesforceId
              }
            }
            roles
            isAdmin
          }
          lastUsedAccount {
            id
            name
          }
          subscriptions {
            brand
            channelType
            type
            status
          }
          marketingConsentLevel
        }
      }
    `,
  });
  return data.me;
};
