import { createAsyncThunk } from '@reduxjs/toolkit';

import { getMachineList } from '../../../service/graphql/machine/getMachineList';

import { AsyncThunkConfig } from '../../store';

import { IMachineListQueryElement } from '../types';

export const getMachineListThunk = createAsyncThunk<IMachineListQueryElement[], void, AsyncThunkConfig>(
  'machineList/getMachineList',
  async (_, { getState }) => {
    const {
      launchDarkly,
      selectedAccount: { activeAccountId },
    } = getState();
    if (!activeAccountId) {
      throw new Error('No accounts or user found.');
    }
    return getMachineList(launchDarkly.data, activeAccountId);
  },
);
