import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import launchDarklyReducer from './LaunchDarkly';
import registrationReducer from './Registration';
import userReducer from './User';
import activeAccountReducer from './UserAccount';
import machineListReducer from './MachineList';
import machineDetailsReducer from './MachineDetails';
import addMachineReducer from './AddMachine';
import claimBusinessReducer from './ClaimBusiness';
import verifyMachineReducer from './VerifyMachine';
import {
  registrationPersistConfig,
  selectedAccountConfig,
  machineDetailsPersistConfig,
  addMachinePersistConfig,
  verifyMachinePersistConfig,
} from './config';

export const reducers = combineReducers({
  launchDarkly: launchDarklyReducer,
  registration: persistReducer(registrationPersistConfig, registrationReducer),
  user: userReducer,
  selectedAccount: persistReducer(selectedAccountConfig, activeAccountReducer),
  machineList: machineListReducer,
  machineDetails: persistReducer(machineDetailsPersistConfig, machineDetailsReducer),
  addMachine: persistReducer(addMachinePersistConfig, addMachineReducer),
  claimBusiness: claimBusinessReducer,
  verifyMachine: persistReducer(verifyMachinePersistConfig, verifyMachineReducer),
});

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AsyncThunkConfig = { state: RootState };
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
