import React, { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { StyleSheetManager } from 'styled-components';

import { store, persistor } from '../../store';

import ThemeProvider from './ThemeProvider';
import LDProvider from './LDProvider/LDProvider';
import MsalProvider from './MsalProvider';
import NotificationProvider from './NotificationProvider';
import IntlProvider from './IntlProvider';

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LDProvider>
          <NotificationProvider>
            <ThemeProvider>
              <MsalProvider>
                <StyleSheetManager disableCSSOMInjection>
                  <IntlProvider>{children}</IntlProvider>
                </StyleSheetManager>
              </MsalProvider>
            </ThemeProvider>
          </NotificationProvider>
        </LDProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default Providers;
