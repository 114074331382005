import { gql } from '@apollo/client';

import { IMachineDetails } from '../../../../store/MachineDetails';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const editFleetDevice = async (
  launchDarkly: TLaunchDarklySet,
  machineData: IMachineDetails,
  shouldSendVin?: boolean,
) => {
  const mutation = gql`
    mutation UpdateFleetDevice($fleetDeviceId: ID!, $fleetDevice: FleetDeviceInput!) {
      updateFleetDevice(fleetDeviceId: $fleetDeviceId, fleetDevice: $fleetDevice) {
        name
      }
    }
  `;

  const fleetDeviceInput: Record<string, any> = {
    name: machineData.name?.replace(/\s+/g, ' ').trim(),
  };

  if (shouldSendVin && machineData.vin) {
    fleetDeviceInput.vin = machineData.vin;
  }

  const result = await client(launchDarkly).mutate<TQueryResponse<'updateFleetDevice', IMachineDetails>, unknown>({
    mutation,
    variables: {
      fleetDeviceId: machineData.id,
      fleetDevice: fleetDeviceInput,
    },
  });

  if (!result.data) {
    throw new Error('No data returned from server');
  }

  return result.data.updateFleetDevice;
};
