import { EOwnershipStatus } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { IMachineListQueryElement, IMachineListElement } from './types';

export const processMachineList = (machineList: IMachineListQueryElement[]) => {
  const processedMachineList = machineList.reduce(
    (processedMachines: IMachineListElement[], machine: IMachineListQueryElement): IMachineListElement[] => {
      // Filter unnecessary machines
      if (machine.ownershipStatus === EOwnershipStatus.REJECTED) {
        return processedMachines;
      }
      // Process data to machine list
      const model = machine.model?.name || machine.modelText || '';
      return [
        ...processedMachines,
        {
          ...machine,
          machineIcon: 'machines-outline',
          name:
            machine.name ??
            (machine.brand?.name || machine?.brandText
              ? `${machine.brand?.name || machine?.brandText} ${model}`
              : model),
          image: machine.image ? `https://test-cep.fendt.com${machine.image}` : undefined,
          brand: (machine.brand?.name || machine?.brandText) ?? '',
          model,
        },
      ];
    },
    [],
  );
  // Sort processed machine list
  processedMachineList.sort((a, b) => a.name.localeCompare(b.name));
  // Return sorted machine list
  return processedMachineList;
};
