import React, { useCallback, useEffect, useState } from 'react';
import { Snackbar, TSnackbarPosition, useSidebarState } from '@agco-onedigital/ecosystem-lib-web-components';
import { getDefaultSnackPosition } from './utils';

const SnackProvider = () => {
  const { isSidebarOpen } = useSidebarState();
  const [snackPosition, setSnackPosition] = useState<TSnackbarPosition>(getDefaultSnackPosition(isSidebarOpen));

  const handleResize = useCallback(() => {
    const newSnackPosition = getDefaultSnackPosition(isSidebarOpen);
    if (snackPosition !== newSnackPosition) {
      setSnackPosition(newSnackPosition);
    }
  }, [isSidebarOpen, snackPosition]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return <Snackbar maxShownSnacks={1} position={snackPosition} />;
};

export default SnackProvider;
