import { gql } from '@apollo/client';
import { client } from '../../graphql';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { TUpdateAccount } from './types';
import { TQueryResponse } from '../../../types';

export const updateAccount = async (
  launchDarkly: TLaunchDarklySet,
  account: TUpdateAccount,
): Promise<TUpdateAccount> => {
  const filteredAccount = Object.fromEntries(Object.entries(account).filter(([_, v]) => v !== undefined));

  const { data } = await client(launchDarkly).mutate<TQueryResponse<'updateAccount', TUpdateAccount>, unknown>({
    mutation: gql`
      mutation updateAccount($account: AccountInput!) {
        updateAccount(account: $account) {
          id
          name
          officePhone
          country
          city
          address
          zipCode
          businessTypes
          farmTypes
          registrationNumber
          entityType
        }
      }
    `,
    variables: {
      account: filteredAccount,
    },
  });
  if (!data) {
    throw new Error('No data returned from server');
  }
  return data.updateAccount;
};
