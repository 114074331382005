import React from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { Snackbar } from '@agco-onedigital/ecosystem-lib-web-components';

import { IAddMachineState } from './types';

const messages = commonMessages.fleet;

const getValues = (state: IAddMachineState) => {
  if (state.data.isVinSkipped) {
    return {
      brand: state.data.nonVinData?.brand?.name,
      model: state.data.nonVinData?.model?.name,
    };
  }
  return {
    brand: state.data.deviceAvailability?.deviceDetails?.brand,
    model: state.data.deviceAvailability?.deviceDetails?.modelName,
  };
};

export const handleCreateDeviceError = (state: IAddMachineState) => {
  state.requestOwnershipError = true;
  state.isLoading = false;
  Snackbar.showSnack({
    title: messages.machineAddedFailedNotiTitle,
    message: (
      <FormattedMessage
        {...messages.machineAddedFailedNotiDescription}
        values={{
          ...getValues(state),
          lineBreak: <br />,
        }}
      />
    ),
    duration: 10 * 1000,
    type: 'error',
  });
};

export const handleCreateDeviceSuccess = (state: IAddMachineState) => {
  Snackbar.showSnack({
    title: messages.newMachineAdded,
    message: (
      <>
        <FormattedMessage {...messages.machineAddedSuccessfully} values={getValues(state)} />
        {state.data.documents?.length ? <FormattedMessage {...messages.ownershipInProgress} /> : null}
      </>
    ),
    duration: 5 * 1000,
  });
  state.isSuccessfulSubmit = true;
};
