import { gql } from '@apollo/client';
import { IModel } from '../../../../store/AddMachine';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';
import { client } from '../../graphql';

export const getModels = async (
  launchDarkly: TLaunchDarklySet,
  modelSeriesIds: string,
  country?: string,
): Promise<IModel[]> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'deviceModels', IModel[]>>({
    query: gql`
      query {
        deviceModels(filter: { modelSeriesIds: "${modelSeriesIds}", countries: ${JSON.stringify(
      country ? [country] : [],
    )} }) {
          id
          modelSeries {
            id
            brand {
              name
            }
            subCategory {
              id
              defaultDisplayName
              key
            }
            name
            deviceType
          }
          name
        }
      }
    `,
  });
  return data.deviceModels;
};
