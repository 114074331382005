import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { AsyncThunkConfig } from '../..';

import { ICheckEmailAndSendOtpThunkReturn, ICheckEmailResponse, ISendOtpResponse, ISendOtpResult } from './types';

export const validateEmail = async (email: string, muleBaseUrl: string, consumerApp: string) => {
  try {
    const { data } = await axios.get<ICheckEmailResponse>(`${muleBaseUrl}/api/customers/identity/${email}`, {
      headers: {
        'x-consumer-app': consumerApp,
      },
    });
    if (data.status === 'Pending') {
      return {};
    }
    return {
      isEmailAlreadyUsed: true,
      isBusinessOwner: data.isBusinessOwner,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return {};
    }
    return {
      emailError: true,
    };
  }
};

export const sendOtpCode = async (
  email: string,
  muleBaseUrl: string,
  consumerApp: string,
  locale: string,
): Promise<ISendOtpResult> => {
  try {
    const { data } = await axios.post<ISendOtpResponse>(
      `${muleBaseUrl}/api/utils/otp`,
      {
        email,
        locale,
      },
      {
        headers: {
          'x-consumer-app': consumerApp,
        },
      },
    );
    return data;
  } catch (error) {
    return {
      requestError: true,
    };
  }
};

export const checkEmailAndSendOtpThunk = createAsyncThunk<ICheckEmailAndSendOtpThunkReturn, string, AsyncThunkConfig>(
  'registration/checkEmailAndSendOTP',
  async (email, { getState }) => {
    const {
      launchDarkly,
      registration: {
        data: { locale = 'en_GB' },
      },
    } = getState();
    const mule = launchDarkly.data['config-web-registration']?.integration?.mule?.applications?.['mule-ep-customers'];
    const muleBaseUrl = mule?.baseUrl || '';
    const consumerApp = mule?.constants?.['consumer-app'] || '';
    if (!muleBaseUrl && !process.env.JEST_WORKER_ID) {
      throw new Error('Mule base url is missing in launch darkly config!');
    }
    const { emailError, isEmailAlreadyUsed, isBusinessOwner } = await validateEmail(email, muleBaseUrl, consumerApp);
    if (emailError || isEmailAlreadyUsed) {
      return {
        emailError,
        isEmailAlreadyUsed,
        isBusinessOwner,
      };
    }

    const { transactionId, requestError } = await sendOtpCode(email, muleBaseUrl, consumerApp, locale);
    if (requestError) {
      return {
        emailError: true,
      };
    }
    return {
      otpTransactionId: transactionId,
      email,
    };
  },
);
