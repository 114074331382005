import { EventMessage, EventType } from '@azure/msal-browser';

import { TLaunchDarklySet } from '../../store/LaunchDarkly';

import { handleLogoutRedirect } from './utils';

export const WARNING_KEY = 'inactivity_warning_displayed';
export const STORAGE_KEY = 'last_activity_date';
export const INACTIVITY_WARNING_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds
export const INACTIVITY_WARNING_TIME_IN_MIN = INACTIVITY_WARNING_TIME / 60 / 1000;

const storeActivity = () => {
  localStorage.setItem(STORAGE_KEY, Date.now().toString());
  localStorage.removeItem(WARNING_KEY);
};

const getActivity = () => localStorage.getItem(STORAGE_KEY);

const getWarningDisplayed = () => localStorage.getItem(WARNING_KEY);

const setWarningDisplayed = () => localStorage.setItem(WARNING_KEY, 'true');

export const handleMsalEvents = (event: Partial<EventMessage>) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    storeActivity();
  } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
    localStorage.removeItem(STORAGE_KEY);
  }
};

const checkInactivity = (launchDarkly: TLaunchDarklySet) => () => {
  const MAX_INACTIVITY_TIME_IN_SEC =
    window.SHARED_MODULE_DATA.launchDarklySet?.['web-maximum-inactivity-time-seconds'] || 24 * 60 * 60;
  const MAX_INACTIVITY_TIME_IN_MS = MAX_INACTIVITY_TIME_IN_SEC * 1000;

  const lastActivity = getActivity();
  if (!lastActivity) return;

  const lastActivityTimestamp = Number(lastActivity);
  if (Number.isNaN(lastActivityTimestamp)) {
    handleLogoutRedirect(launchDarkly);
    return;
  }

  const warningTime = MAX_INACTIVITY_TIME_IN_MS - INACTIVITY_WARNING_TIME;

  const currentTime = Date.now();
  if (currentTime > lastActivityTimestamp + MAX_INACTIVITY_TIME_IN_MS) {
    localStorage.removeItem(WARNING_KEY);
    handleLogoutRedirect(launchDarkly, true);
  } else if (currentTime > lastActivityTimestamp + warningTime && !getWarningDisplayed()) {
    window.dispatchEvent(new CustomEvent('inactivityEvent'));
    setWarningDisplayed();
  }
};

const trackActivity = () => {
  const lastActivity = getActivity();
  if (!lastActivity) return;
  storeActivity();
};

const setupActivityTracker = () => {
  ['click', 'keydown', 'scroll', 'beforeunload'].forEach(event => {
    window.addEventListener(event, trackActivity);
  });
};

export const setupInactivityChecker = (launchDarkly: TLaunchDarklySet) => {
  checkInactivity(launchDarkly)();
  setInterval(checkInactivity(launchDarkly), 5000);
  setupActivityTracker();
};
