import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSeries } from '../../../service/graphql/machine/getSeries';

import { AsyncThunkConfig } from '../../store';
import { ISerie } from '../types';
import { selectCurrentAccount } from '../../User';

export const getSeriesThunk = createAsyncThunk<ISerie[], void, AsyncThunkConfig>(
  'addMachine/getSeries',
  async (_, { getState }) => {
    const { launchDarkly, addMachine } = getState();
    const country = selectCurrentAccount(getState()).userAccount?.account.country;
    const subCategoryId = addMachine.data.nonVinData?.subCategory?.id ?? '';
    return getSeries(launchDarkly.data, addMachine.data.nonVinData?.brand?.id ?? '', subCategoryId, country);
  },
);
