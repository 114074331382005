import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EPaginationQueries, ROWS_PER_PAGE_OPTIONS } from '@agco-onedigital/ecosystem-lib-web-components';

import { querify } from '../querify';

const getTotalPages = (totalItems: number, rowsPerPage: number) => Math.ceil(totalItems / rowsPerPage);

const getPage = (pageString: string | null, totalItems: number, rowsPerPage: number) => {
  if (!pageString || pageString === '0') {
    return 0;
  }
  let page = Number(pageString);
  if (Number.isNaN(page)) {
    return 0;
  }
  page -= 1;
  const totalPages = getTotalPages(totalItems, rowsPerPage);
  if (page >= totalPages) {
    return totalPages - 1;
  }
  if (page < 1) {
    return 0;
  }
  return page;
};

const getRowsPerPage = (rowsPerPageString: string | null) => {
  const queryOption = ROWS_PER_PAGE_OPTIONS.find(option => option.label === rowsPerPageString);
  if (queryOption) {
    return queryOption.value;
  }
  return 10;
};

export const usePagination = <T>(items: T[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPage(searchParams.get(EPaginationQueries.ROWS_PER_PAGE)));
  const [page, setPage] = useState(getPage(searchParams.get(EPaginationQueries.PAGE), items.length, rowsPerPage));
  const slicedItems = items.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  useEffect(() => {
    if (items.length) {
      const totalPages = getTotalPages(items.length, rowsPerPage);
      if (page >= totalPages) {
        setPage(totalPages - 1);
      }
    }
  }, [items, rowsPerPage, page]);

  useEffect(() => {
    const query = querify({
      [EPaginationQueries.PAGE]: page === 0 ? undefined : page + 1,
      [EPaginationQueries.ROWS_PER_PAGE]: rowsPerPage === 10 ? undefined : rowsPerPage,
    });
    setSearchParams(query);
  }, [page, rowsPerPage, setSearchParams]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setPage],
  );

  const handleRowPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return {
    rowsPerPage,
    page,
    items: slicedItems,
    onPageChange: handlePageChange,
    onRowPerPageChange: handleRowPerPageChange,
  };
};
