import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';

import { TLaunchDarklySet } from '../../store/LaunchDarkly';
import { handleMsalEvents, setupInactivityChecker } from './inactivityLogout';

import { msalConfig } from './msalConfig';
import { handleLoginRedirect, handleLogoutRedirect } from './utils';

let msalInstance: PublicClientApplication | undefined;

const getMsalInstance = (launchDarkly: TLaunchDarklySet, isFresh?: boolean) => {
  if (msalInstance && !isFresh && !process.env.JEST_WORKER_ID) {
    return msalInstance;
  }
  msalInstance = new PublicClientApplication(msalConfig(launchDarkly));

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event?.payload as AuthenticationResult)?.account) {
      const { account } = event.payload as AuthenticationResult;
      if (msalInstance) {
        msalInstance.setActiveAccount(account);
      }
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
      handleLoginRedirect(launchDarkly);
    } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE || event.eventType === EventType.ACCOUNT_REMOVED) {
      handleLogoutRedirect(launchDarkly);
    } else if (event.eventType === EventType.HANDLE_REDIRECT_END) {
      setupInactivityChecker(launchDarkly);
    }

    handleMsalEvents(event);
  });

  return msalInstance;
};

export default getMsalInstance;
