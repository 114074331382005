import React from 'react';
import { ModuleRouterSync } from '@agco-onedigital/ecosystem-lib-web-components';
import ModuleLoader from '.';
import { useLaunchDarkly } from '../../store/LaunchDarkly';

const FSEModuleLoader = () => {
  const moduleProps = useLaunchDarkly()['config-web-app']?.ecosystem?.fseModule;

  const src = `${moduleProps?.host}${moduleProps?.pathname}`;

  return (
    <ModuleRouterSync>
      <ModuleLoader src={src} id="fleet-module-root" />
    </ModuleRouterSync>
  );
};

export default FSEModuleLoader;
