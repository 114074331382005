import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBrands } from '../../../service/graphql/machine/getBrands';

import { AsyncThunkConfig } from '../../store';
import { IBrand } from '../types';
import { selectCurrentAccount } from '../../User';

export const getBrandsThunk = createAsyncThunk<IBrand[], void, AsyncThunkConfig>(
  'addMachine/getBrands',
  async (_, { getState }) => {
    const { launchDarkly } = getState();
    const country = selectCurrentAccount(getState()).userAccount?.account.country;
    return getBrands(launchDarkly.data, country);
  },
);
