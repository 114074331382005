import { UserSubscription } from '@agco-onedigital/ecosystem-lib-frontend-utils';

export const getUpdatedSubscriptions = (initialSubs: UserSubscription[], currentSubs: UserSubscription[]) => {
  const updatedSubs: UserSubscription[] = [];

  // Create a map of initial subscriptions for quick lookup
  const initialSubsMap = new Map<string, UserSubscription>();
  initialSubs.forEach(sub => {
    const key = `${sub.brand}-${sub.channelType}-${sub.type}`;
    initialSubsMap.set(key, sub);
  });

  // Iterate over current subscriptions
  currentSubs.forEach(sub => {
    const key = `${sub.brand}-${sub.channelType}-${sub.type}`;
    const initialSub = initialSubsMap.get(key);

    if (!initialSub || initialSub.status !== sub.status) {
      // Subscription is new or status has changed
      updatedSubs.push(sub);
    }
  });

  // Also check for subscriptions that were removed
  initialSubs.forEach(sub => {
    const key = `${sub.brand}-${sub.channelType}-${sub.type}`;
    if (!currentSubs.some(s => s.brand === sub.brand && s.channelType === sub.channelType && s.type === sub.type)) {
      // Subscription was removed, add it with 'Opt Out' status
      updatedSubs.push({ ...sub, status: 'Opt Out' });
    }
  });

  return updatedSubs;
};
