import { createAsyncThunk } from '@reduxjs/toolkit';
import { getModelVariants } from '../../../service/graphql/machine/getModelVariants';

import { AsyncThunkConfig } from '../../store';
import { IModelVariant } from '../types';
import { selectCurrentAccount } from '../../User';

export const getModelVariantsThunk = createAsyncThunk<IModelVariant[], string, AsyncThunkConfig>(
  'addMachine/getModelVariants',
  async (modelId, { getState }) => {
    const { launchDarkly } = getState();
    const country = selectCurrentAccount(getState()).userAccount?.account.country;
    return getModelVariants(launchDarkly.data, modelId, country);
  },
);
