import { gql } from '@apollo/client';

import { IDeviceAvailability } from '../../../../store/AddMachine';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

import { IDeviceAvailabilityVariables } from './types';

const queryName = 'deviceAvailability';

export const deviceAvailability = async (launchDarkly: TLaunchDarklySet, vin: string, accountId: string) => {
  const { data } = await client(launchDarkly).query<
    TQueryResponse<typeof queryName, IDeviceAvailability>,
    IDeviceAvailabilityVariables
  >({
    query: gql`
      query DeviceAvailability($accountId: ID!, $vin: String!) {
        deviceAvailability(accountId: $accountId, vin: $vin) {
          deviceDetails {
            brand
            type
            categoryName
            subCategoryName
            modelSeriesName
            modelName
            modelVariantName
            yearOfManufacture
          }
          status
          vin
        }
      }
    `,
    variables: {
      vin,
      accountId,
    },
  });
  return data[queryName];
};
