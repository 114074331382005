import { useState } from 'react';

import { IAddMachineState, IDeviceAvailability } from '../../store/AddMachine';
import { initialMinDate } from '../../pages/AddMachine/const';

import { getCalendarValidationValues } from '../getCalendarValidationValues';

export const useAddMachineDateAndCondition = (
  storedStartDate: IAddMachineState['data']['startDate'],
  storedcondition: IAddMachineState['data']['condition'],
  yearOfManufacture:
    | NonNullable<IAddMachineState['data']['nonVinData']>['yearOfManufacture']
    | NonNullable<IDeviceAvailability['deviceDetails']>['yearOfManufacture'],
) => {
  const [startDate, setStartDate] = useState(storedStartDate ? new Date(storedStartDate) : null);
  const [condition, setCondition] = useState(storedcondition);

  const getMinDateValue = (() => {
    if (!yearOfManufacture) {
      return initialMinDate;
    }
    if (typeof yearOfManufacture === 'string') {
      return yearOfManufacture;
    }
    return new Date(yearOfManufacture, 0, 1);
  })();

  return {
    startDate,
    setStartDate,
    condition,
    setCondition,
    ...getCalendarValidationValues(startDate, getMinDateValue),
  };
};
