import { createAsyncThunk } from '@reduxjs/toolkit';

import { IUserData } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { saveUserData } from '../../../service/graphql/user/saveUserData';
import { sleep } from '../../../utils/sleep';

import { AsyncThunkConfig } from '../../store';
import { getUpdatedSubscriptions } from './utils';

export const saveUserThunk = createAsyncThunk<IUserData, Partial<IUserData>, AsyncThunkConfig>(
  'user/saveUser',
  async (userChanges, { getState }) => {
    const {
      launchDarkly,
      user: { data: user },
    } = getState();
    if (!user) {
      throw new Error('User not loaded.');
    }
    if (process.env.NODE_ENV === 'development') {
      await sleep(400);
    }

    const newUser = {
      ...user,
      ...userChanges,
      firstName: userChanges.firstName?.replace(/\s+/g, ' ').trim() ?? user.firstName,
      lastName: userChanges.lastName?.replace(/\s+/g, ' ').trim() ?? user.lastName,
    };

    const updatedSubscriptions = getUpdatedSubscriptions(user.subscriptions ?? [], newUser.subscriptions ?? []);

    const updateUserDto = {
      ...newUser,
    };

    if (updatedSubscriptions.length > 0) {
      updateUserDto.subscriptions = updatedSubscriptions;
    } else {
      delete updateUserDto.subscriptions;
    }

    //    TODO MARKETING: remove this when API ready
    localStorage.setItem('user-marketing', JSON.stringify(userChanges.marketingConsents || user.marketingConsents));
    //

    // Save user data, including only updated subscriptions
    await saveUserData(launchDarkly.data, updateUserDto);

    return newUser as IUserData;
  },
);
