import React from 'react';

const PageNotFound = () => {
  return (
    <img
      // eslint-disable-next-line max-len
      src="https://images.squarespace-cdn.com/content/v1/5bcfa390b10f257936b5b36e/1562119279035-P7IVSLT97S54OA7EZ1R6/Cardknox-404.gif?format=2500w"
      alt="Page not found"
      style={{ width: '95%' }}
    />
  );
};

export default PageNotFound;
