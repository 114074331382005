import { gql } from '@apollo/client';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { IMachineListQueryElement } from '../../../../store/MachineList';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getMachineList = async (launchDarkly: TLaunchDarklySet, accountId: string) => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'fleetDevices', IMachineListQueryElement[]>>({
    query: gql`
      query {
        fleetDevices (filter: { accountId: "${accountId}" }) {
          vin
          id
          brand {
            name
          }
          model {
            name
          }
          subCategoryText
          categoryText
          brandText
          modelText
          name
          ownershipStatus
          image
        }
      }
    `,
  });
  return data.fleetDevices;
};
