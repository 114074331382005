import { gql } from '@apollo/client';
import { ISubCategory } from '../../../../store/AddMachine';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getSubCategories = async (
  launchDarkly: TLaunchDarklySet,
  brandIds: string,
  categoryIds: string,
  country?: string,
): Promise<ISubCategory[]> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'deviceSubCategories', ISubCategory[]>>({
    query: gql`
      query {
        deviceSubCategories (filter: { brandIds: "${brandIds}", categoryIds: "${categoryIds}", countries: ${JSON.stringify(
      country ? [country] : [],
    )} }) {
          category {
            defaultDisplayName
            id
            key
          }
          defaultDisplayName
          deviceType
          id
          key
        }
      }
    `,
  });
  return data.deviceSubCategories;
};
