import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '../../store';
import { updateAccount } from '../../../service/graphql/user/updateAccount/updateAccount';
import { TUpdateAccount } from '../../../service/graphql/user/updateAccount/types';

export const updateAccountThunk = createAsyncThunk<TUpdateAccount, TUpdateAccount, AsyncThunkConfig>(
  'user/updateAccount',
  async (account, { getState }) => {
    const { launchDarkly } = getState();
    return updateAccount(launchDarkly.data, account);
  },
);
