import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { EAuthRoutes } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { TLaunchDarklySet, useLaunchDarkly } from '../../store/LaunchDarkly';

export const useRedirectDisabledPages = (...flags: Array<keyof TLaunchDarklySet>) => {
  const launchDarkly = useLaunchDarkly();
  const navigate = useNavigate();
  const { name } = useTheme();
  const currentFlag = flags.length === 1 ? flags[0] : flags.find(flag => flag.includes(name)) ?? flags[0];

  useEffect(() => {
    if (!launchDarkly[currentFlag]) {
      navigate(EAuthRoutes.DASHBOARD);
    }
  }, [launchDarkly, navigate, currentFlag]);
};
