import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISearchAddress } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { client } from '../../../service/graphql/graphql';
import { addressSuggestions } from '../../../service/graphql/user/addressSuggestions';
import { AsyncThunkConfig } from '../../store';

export const addressSuggestionsPostLoginThunk = createAsyncThunk<
  ISearchAddress[],
  { addressLine: string; countryCode: string },
  AsyncThunkConfig
>('user/addressSuggestionsPostLogin', async (addressInput, { getState }) => {
  const { launchDarkly } = getState();

  return addressSuggestions(client(launchDarkly.data), launchDarkly.data, {
    addressLine: addressInput.addressLine,
    countryCode: addressInput.countryCode,
  });
});
