import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const isBackButtonClicked = useRef(false);

  useEffect(() => {
    const handlePopState = () => {
      isBackButtonClicked.current = true;
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isBackButtonClicked.current) {
        isBackButtonClicked.current = false;
      } else {
        window.scrollTo(0, 0);
      }
    }, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
