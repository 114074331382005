import { gql } from '@apollo/client';
import { IBrand } from '../../../../store/AddMachine';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getBrands = async (launchDarkly: TLaunchDarklySet, country?: string): Promise<IBrand[]> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'brands', IBrand[]>>({
    query: gql`
      query {
        brands(filter: { countries: ${JSON.stringify(country ? [country] : [])} }) {
          id
          isAgco
          name
        }
      }
    `,
  });
  return data.brands;
};
