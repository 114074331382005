import { RootState, useAppSelector } from '..';

export const selectUser = (state: RootState) => state.user;
export const useUser = () => useAppSelector(selectUser);

export const selectCurrentAccount = (state: RootState) => ({
  userAccount: state.user?.data?.accounts.find(acc => acc.account.id === state.selectedAccount.activeAccountId),
  ...state.user,
});
export const useCurrentAccount = () => useAppSelector(selectCurrentAccount);
