import { createAsyncThunk } from '@reduxjs/toolkit';

import { createDeviceAgcoByVin } from '../../../service/graphql/machine/createDeviceAgcoByVin';

import { AsyncThunkConfig } from '../../store';
import { createDeviceNonAgco } from '../../../service/graphql/machine/createDeviceNonAgco';
import { createDeviceAgco } from '../../../service/graphql/machine/createDeviceAgco';
import { fireCustomEvent } from '../../../utils/fireCustomEvent';
import { getFormattedDate } from '../../../utils/getFormattedDate';

export const createDeviceThunk = createAsyncThunk<void, undefined, AsyncThunkConfig>(
  'addMachine/createDevice',
  async (_, { getState }) => {
    const {
      launchDarkly,
      addMachine: {
        data: { condition, nonVinData, deviceAvailability, startDate, documents },
      },
      selectedAccount: { activeAccountId },
    } = getState();

    if (!activeAccountId) {
      throw new Error('No account selected!');
    }

    const trackedStartDate = startDate ? getFormattedDate(startDate, 'MMMM YYYY') : '';
    // Determine which API call to make based on the conditions
    if (deviceAvailability?.vin) {
      return createDeviceAgcoByVin(launchDarkly.data, activeAccountId, {
        vin: deviceAvailability?.vin,
        condition,
        startDate,
        documents,
      }).then(() => {
        fireCustomEvent({
          event: 'AddMachine',
          Brand: deviceAvailability?.deviceDetails?.brand,
          BrandType: 'AGCO',
          VIN: deviceAvailability?.vin,
          Category: deviceAvailability?.deviceDetails?.categoryName,
          SubCategory: deviceAvailability?.deviceDetails?.subCategoryName,
          Series: deviceAvailability?.deviceDetails?.modelSeriesName,
          Model: deviceAvailability?.deviceDetails?.modelName,
          ModelVariant: deviceAvailability?.deviceDetails?.modelVariantName,
          YearOfManufacturing: deviceAvailability?.deviceDetails?.yearOfManufacture.toString(),
          DateofPurchase: trackedStartDate,
          Condition: condition ?? '',
        });
      });
    }

    if (!nonVinData || !condition) {
      throw new Error('Not enough data to create device, nonVinData is empty');
    }

    if (!nonVinData?.brand?.isAgco) {
      const { brand, category, subCategory, customModelName, yearOfManufacture } = nonVinData;

      if (!brand || !subCategory) {
        throw new Error('Not enough data to createDeviceNonAgco');
      }

      return createDeviceNonAgco(launchDarkly.data, activeAccountId, {
        brandId: brand.id,
        subCategoryId: subCategory.id,
        condition,
        modelText: customModelName ?? '',
        ownershipStartDate: startDate ?? '',
        yearOfManufacture: yearOfManufacture ?? '',
      }).then(() => {
        fireCustomEvent({
          event: 'AddMachine',
          Brand: brand.name,
          BrandType: 'Non-AGCO',
          Category: category?.defaultDisplayName,
          SubCategory: subCategory.defaultDisplayName,
          Model: deviceAvailability?.deviceDetails?.modelName,
          YearOfManufacturing: yearOfManufacture ? getFormattedDate(yearOfManufacture, 'YYYY') : '',
          DateofPurchase: trackedStartDate,
          Condition: condition ?? '',
        });
      });
    }

    const { brand, category, subCategory, serie, model, modelVariant, yearOfManufacture } = nonVinData;
    return createDeviceAgco(launchDarkly.data, activeAccountId, {
      nonVinData,
      condition,
      ownershipStartDate: startDate,
    }).then(() => {
      fireCustomEvent({
        event: 'AddMachine',
        Brand: brand?.name,
        BrandType: 'AGCO',
        Category: category?.defaultDisplayName,
        SubCategory: subCategory?.defaultDisplayName,
        Series: serie?.name,
        Model: model?.name,
        ModelVariant: modelVariant?.name,
        YearOfManufacturing: yearOfManufacture ? getFormattedDate(yearOfManufacture, 'YYYY') : '',
        DateofPurchase: trackedStartDate,
        Condition: condition ?? '',
      });
    });
  },
);
