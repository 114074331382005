import { PropsWithChildren, useCallback, useEffect } from 'react';
import { getMessaging, isSupported } from 'firebase/messaging';
import { onMessageListener, requestPermission, isPrivateMode } from './utils';
import { LOCAL_HOST_NAME, TLS_PROTOCOL } from './consts';

const NotificationProvider = ({ children }: PropsWithChildren) => {
  const pushHandler = useCallback(async () => {
    const isSupportedBrowser = await isSupported();
    const { hostname, protocol } = window.location;

    if (isSupportedBrowser && !isPrivateMode() && (hostname === LOCAL_HOST_NAME || protocol === TLS_PROTOCOL)) {
      const messaging = getMessaging();
      requestPermission(messaging);
      onMessageListener(messaging).then(payload => {});
    }
  }, []);

  useEffect(() => {
    pushHandler();
  }, [pushHandler]);

  return children as JSX.Element;
};

export default NotificationProvider;
