import { createAsyncThunk } from '@reduxjs/toolkit';

import { deviceAvailability } from '../../../service/graphql/machine/deviceAvailability';

import { AsyncThunkConfig } from '../../store';
import { IDeviceAvailability } from '../../AddMachine';

export const verifyVinDeviceAvailabilityThunk = createAsyncThunk<
  IDeviceAvailability | undefined,
  string,
  AsyncThunkConfig
>('verifyMachine/verifyVinDeviceAvailability', async (vin, { getState }) => {
  const {
    launchDarkly,
    selectedAccount: { activeAccountId },
  } = getState();
  if (!activeAccountId) {
    throw new Error('No account selected!');
  }
  return deviceAvailability(launchDarkly.data, vin, activeAccountId);
});
