import { gql } from '@apollo/client';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { client } from '../../graphql';
import { getStartDateString } from '../utils';
import { ICreateDeviceNonAgcoMutationParams } from '.';

const mutationName = 'createDeviceNonAgco';

export const createDeviceNonAgco = async (
  launchDarkly: TLaunchDarklySet,
  accountId: string,
  params: ICreateDeviceNonAgcoMutationParams,
) => {
  const { brandId, subCategoryId, modelText, ownershipStartDate, condition, yearOfManufacture } = params;

  if (!ownershipStartDate) {
    throw new Error('Missing start date.');
  }
  const formattedOwnershipStartDate = getStartDateString(ownershipStartDate);

  const formattedYearOfManufacture: number = yearOfManufacture
    ? new Date(yearOfManufacture).getFullYear()
    : +yearOfManufacture!;

  const mutation = gql`
    mutation {
      ${mutationName}(
        device: {
          accountId: "${accountId}",
          brandId: "${brandId}",
          subCategoryId: "${subCategoryId}",
          modelText: "${modelText}",
          ownershipStartDate: "${formattedOwnershipStartDate}",
          condition: ${condition},
          yearOfManufacture: ${formattedYearOfManufacture}
        }
      ) {
        id
      }
    }
  `;

  await client(launchDarkly).mutate({ mutation });
};
