import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { client } from '../../graphql';
import { getStartDateString } from '../utils';
import { ICreateDeviceAgcoMutationParams } from './types';

export const createDeviceAgco = async (
  launchDarkly: TLaunchDarklySet,
  accountId: string,
  params: ICreateDeviceAgcoMutationParams,
) => {
  const { nonVinData, ownershipStartDate, condition } = params;

  if (!nonVinData) {
    throw new Error('Non-VIN data is required');
  }

  if (!ownershipStartDate) {
    throw new Error('Missing start date.');
  }

  const { brand, subCategory, serie, model, modelVariant, yearOfManufacture } = nonVinData;

  const formattedOwnershipStartDate = getStartDateString(ownershipStartDate);

  const formattedYearOfManufacture: number = yearOfManufacture
    ? new Date(yearOfManufacture).getFullYear()
    : +yearOfManufacture!;

  let mutationName = '';
  let deviceParams = '';

  if (modelVariant?.id) {
    mutationName = 'createDeviceAgcoByModelVariant';
    deviceParams = `
      modelVariantId: "${modelVariant.id}"
    `;
  } else if (model?.id) {
    mutationName = 'createDeviceAgcoByModel';
    deviceParams = `
      modelId: "${model.id}", 
      modelVariantText: "${nonVinData.customModelVariantName}"
    `;
  } else if (serie?.id) {
    mutationName = 'createDeviceAgcoByModelSeries';
    deviceParams = `
      modelSeriesId: "${serie.id}", 
      modelText: "${nonVinData.customModelName}", 
      modelVariantText: "${nonVinData.customModelVariantName}"
    `;
  } else if (subCategory?.id) {
    mutationName = 'createDeviceAgcoBySubCategory';
    deviceParams = `
      subCategoryId: "${subCategory.id}", 
      brandId: "${brand?.id}", 
      modelSeriesText: "${nonVinData.customSerieName}", 
      modelText: "${nonVinData.customModelName}", 
      modelVariantText: "${nonVinData.customModelVariantName}"
    `;
  } else {
    throw new Error('Insufficient data for creating device');
  }

  const mutation = gql`
    mutation {
      ${mutationName}(
        device: {
          accountId: "${accountId}",
          ${deviceParams},
          ownershipStartDate: "${formattedOwnershipStartDate}",
          condition: ${condition},
          yearOfManufacture: ${formattedYearOfManufacture}
        }
      ) {
        id
      }
    }
  `;

  await client(launchDarkly).mutate({ mutation });
};
