import { useEffect } from 'react';
import { TLaunchDarklySet, useLaunchDarkly } from '../store/LaunchDarkly';
import { getAuthDataLD } from './msalConfiguration/utils';

const dtrumUserSetup = async (launchDarkly: TLaunchDarklySet) => {
  const authDetails = await getAuthDataLD(launchDarkly);
  if (window.dtrum) {
    // Identify Dynatrace user
    if (authDetails.account?.username) window.dtrum.identifyUser(authDetails.account?.username);
  }
};

export const useDtrumUserSetup = () => {
  const launchDarkly = useLaunchDarkly();
  useEffect(() => {
    dtrumUserSetup(launchDarkly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
