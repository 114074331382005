import { EAddMachineSteps } from '../../pages/AddMachine/types';
import { TImportStatus } from '../../service/types';

interface IDeviceAvailabilityDetails {
  brand: string;
  categoryName: string;
  subCategoryName: string;
  modelSeriesName: string;
  modelName: string;
  modelVariantName: string;
  type: string;
  yearOfManufacture: number;
}

export type TDeviceAvailabilityStatus = 'AVAILABLE' | 'UNAVAILABLE' | 'ALREADY_IN_ACCOUNT';
export interface IDeviceAvailability {
  vin: string;
  status: TDeviceAvailabilityStatus;
  deviceDetails?: IDeviceAvailabilityDetails;
}

export type TVinError = 'not-found' | 'already-in-account' | 'service-error';

export enum EMachineCondition {
  New = 'NEW',
  Used = 'USED',
}

export interface IOwnershipVerificationDocument {
  id: string;
  fileId?: string;
  fileName: string;
  size: number;
  status: TImportStatus;
  progress: number;
  error?: string;
}

export interface ISerie {
  id: string;
  brandId: string;
  subCategoryId: string;
  name: string;
  deviceType: string;
}

export interface IModel {
  deviceType: string;
  id: string;
  modelSeriesId: string;
  name: string;
}

export interface IModelVariant {
  deviceType: string;
  id: string;
  modelId: string;
  name: string;
}

export interface ICategory {
  defaultDisplayName: string;
  id: string;
  key: string;
}

export interface IBrand {
  id: string;
  name: string;
  isAgco?: boolean;
}

export interface ISubCategory {
  id: string;
  categoryId: string;
  key: string;
  defaultDisplayName: string;
  deviceType: string;
}

export interface IAddMachineState {
  data: {
    currentStep?: EAddMachineSteps;
    summaryEditedStep?:
      | EAddMachineSteps.Brand
      | EAddMachineSteps.Category
      | EAddMachineSteps.MachineInformation
      | EAddMachineSteps.DateAndCondition;
    isVinSkipped?: boolean;
    deviceAvailability?: IDeviceAvailability;
    condition?: EMachineCondition;
    startDate?: string;
    documents?: IOwnershipVerificationDocument[];
    categories?: ICategory[];
    brands?: IBrand[];
    subCategories?: ISubCategory[];
    series?: ISerie[];
    models?: IModel[];
    modelVariants?: IModelVariant[];
    nonVinData?: {
      brand?: IBrand;
      category?: ICategory;
      subCategory?: ISubCategory;
      serie?: ISerie;
      model?: IModel;
      modelVariant?: IModelVariant;
      customSerieName?: string;
      customModelName?: string;
      customModelVariantName?: string;
      customSeriesOption?: ISerie;
      customModelsOption?: IModel;
      customModelVariantsOption?: IModelVariant;
      yearOfManufacture?: string;
    };
    isVerifyVinFlow?: boolean;
  };
  isLoading?: boolean;
  requestOwnershipError?: boolean;
  vinError?: TVinError;
  isCategoryLoading?: boolean;
  isBrandLoading?: boolean;
  isSubCategoryLoading?: boolean;
  isSeriesLoading?: boolean;
  isModelsLoading?: boolean;
  isModelVariantsLoading?: boolean;
  isSuccessfulSubmit?: boolean;
}
