import { gql } from '@apollo/client';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { IMachineDetails } from '../../../../store/MachineDetails';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getFleetDevice = async (launchDarkly: TLaunchDarklySet, fleetDeviceId: string) => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'fleetDevice', IMachineDetails>>({
    query: gql`
      query {
        fleetDevice(fleetDeviceId: "${fleetDeviceId}") {
          id
          type
          account {
            id
          }
          brand {
            id
            name
            isAgco
          }
          brandText
          subCategory {
            defaultDisplayName
            category {
              defaultDisplayName
            }
          }
          modelSeries {
            name
          }
          modelSeriesText
          model {
            name
          }
          categoryText
          subCategoryText
          modelText
          modelVariant {
            name
          }
          modelVariantText
          name
          vin
          ownershipStartDate
          ownershipStatus
          
          yearOfManufacture
          condition

        }
      }
    `,
  });
  return data.fleetDevice;
};
