import { TBrand } from '@agco-onedigital/ecosystem-lib-brand-styles';
import { TLaunchDarklySet, useLaunchDarkly } from '../../store/LaunchDarkly';

export const getMachineManagementEnabled = (launchDarkly: TLaunchDarklySet, brand: TBrand) => {
  switch (brand) {
    case 'valtra':
      return launchDarkly['machine-management-enabled-web-valtra'] || false;
    case 'massey':
      return launchDarkly['machine-management-enabled-web-massey'] || false;
    case 'agco':
      return launchDarkly['machine-management-enabled-web-agco'] || false;
    default:
      return false;
  }
};

export const useServiceInformationLinks = () => {
  const launchDarkly = useLaunchDarkly();
  const externalLinks = launchDarkly['config-web-app']?.ecosystem?.externalLinks;
  if (!externalLinks || !launchDarkly['smart-farming-enabled-web-global']) {
    return undefined;
  }
  const brands = ['fendt', 'valtra', 'massey'] as const;
  return brands.reduce((all, item) => {
    return {
      ...all,
      [item]: launchDarkly[`smart-farming-enabled-web-${item}`] ? externalLinks[item] : '',
    };
  }, {} as { [brand in (typeof brands)[number]]: string });
};
