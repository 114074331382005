import { GlobalStyle, ThemeProvider, Typography } from '@agco-onedigital/ecosystem-lib-web-components';
import React, { useEffect, useState } from 'react';
import HeadProvider from '../../containers/Providers/HeadProvider';
import { IProps } from './types';
import { Container, Content, Description, StyledLogo, Title } from './styles';

const DataSavingProvider = ({ children }: IProps) => {
  const [isSavingEnabled, setIsSavingEnabled] = useState<null | boolean>(null);

  useEffect(() => {
    try {
      localStorage.getItem('');
      setIsSavingEnabled(true);
    } catch {
      setIsSavingEnabled(false);
    }
  }, []);
  if (isSavingEnabled === null) {
    return null;
  }
  if (isSavingEnabled) {
    return children;
  }

  return (
    <ThemeProvider brand="agco">
      <HeadProvider brand="agco" />
      <GlobalStyle />
      <Container>
        <Content>
          <StyledLogo variant="primary" mode="light" />
          <Title size="xxl" bold>
            We can&apos;t sign you in
          </Title>
          <Description size="xxl">
            Your browser is currently set to block cookies. You need to allow cookies to use this service.
          </Description>
          <Typography.Body size="xxl">
            Cookies are small text files stored on your computer that tell us when you&apos;re signed in. To learn how
            to allow cookies, check the online help in your web browser.
          </Typography.Body>
        </Content>
      </Container>
    </ThemeProvider>
  );
};

export default DataSavingProvider;
