import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EClaimBusinessSteps } from '../../pages/ClaimBusiness/types';

import { TClaimBusinessState } from './types';

const initialState: TClaimBusinessState = {
  data: {
    currentStep: EClaimBusinessSteps.ACTIVATION_CODE,
  },
};

const userSlice = createSlice({
  name: 'claimBusiness',
  initialState,
  reducers: {
    setActivationCode: (state, action: PayloadAction<string>) => {
      state.data.activationCode = action.payload;
      state.data.currentStep = EClaimBusinessSteps.COMPANY_DETAILS;
    },
    resetClaimBusiness: state => {
      state.data = initialState.data;
    },
  },
});

export const { setActivationCode, resetClaimBusiness } = userSlice.actions;
const claimBusinessReducer = userSlice.reducer;

export default claimBusinessReducer;
