import { Logo, Typography } from '@agco-onedigital/ecosystem-lib-web-components';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.bg.white};
  display: flex;
  justify-content: center;
  align-items: center;

  ${p =>
    p.theme.mobileLg.max(css`
      padding: 0 16px;
    `)}
`;

export const Content = styled.div`
  max-width: 584px;
  background-color: ${({ theme }) => theme.colors.bg.white};
  padding: 24px 24px 32px 24px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  ${p =>
    p.theme.tabletSm.max(css`
      margin: 16px 0;
    `)}
`;

export const Description = styled(Typography.Body)`
  margin: 24px 0;
`;

export const StyledLogo = styled(Logo)`
  width: 178px;
  padding: 40px 0;
`;

export const Title = styled(Typography.Body)`
  margin-top: 24px;
`;
