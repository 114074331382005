export const fileToBase64 = async (file: File) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise<string>((resolve, reject) => {
    reader.onload = async e => {
      const result = e.target?.result;
      if (typeof result === 'string') {
        resolve(result.split(',')[1]);
      } else {
        reject(new Error('Error during reading file.'));
      }
    };
    reader.onerror = event => reject(event);
  });
};
