import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useOnPathnameChange = (callback: () => void) => {
  const location = useLocation();
  const initialPathname = useRef(location.pathname);
  useEffect(() => {
    if (location.pathname !== initialPathname.current) {
      callback();
      initialPathname.current = location.pathname;
    }
  }, [location.pathname, callback]);
};
