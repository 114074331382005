import { gql } from '@apollo/client';
import { ISerie } from '../../../../store/AddMachine';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getSeries = async (
  launchDarkly: TLaunchDarklySet,
  brandIds: string,
  subCategoryIds: string,
  country?: string,
): Promise<ISerie[]> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'deviceModelSeriesList', ISerie[]>>({
    query: gql`
      query {
        deviceModelSeriesList (filter: { brandIds: "${brandIds}", subCategoryIds: "${subCategoryIds}",  countries: ${JSON.stringify(
      country ? [country] : [],
    )} }) {
          id
          name
          brand {
            name
            id
            isAgco
          }
          deviceType
          subCategory {
            id
          }
        }
      }
    `,
  });
  return data.deviceModelSeriesList;
};
