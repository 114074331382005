import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';

import { TLaunchDarklySet } from '../../store/LaunchDarkly';

import { getBrowserLanguage } from '../languageOptions';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const getAdConfig = (launchDarkly: TLaunchDarklySet) => {
  const azureActiveDirectory = launchDarkly['config-web-app']?.microsoft?.azureActiveDirectory;
  if (!azureActiveDirectory) {
    throw new Error('Azure AD is not defined!');
  }
  return azureActiveDirectory;
};

const getAuthConfig = (launchDarkly: TLaunchDarklySet) => {
  const azureActiveDirectory = getAdConfig(launchDarkly);
  const { clientId, authority, redirectUrl, knownAuthorities } = azureActiveDirectory;
  if (!clientId || !authority || !redirectUrl || !knownAuthorities) {
    throw new Error('Azure AD is not fully defined!');
  }

  return {
    clientId,
    authority,
    knownAuthorities,
    redirectUrl,
  };
};

// Config object to be passed to Msal on creation
export const msalConfig = (launchDarkly: TLaunchDarklySet) => ({
  auth: getAuthConfig(launchDarkly),
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },

  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
});

export const getLoginRequest = (launchDarkly: TLaunchDarklySet) => {
  const { clientId } = getAdConfig(launchDarkly);
  if (!clientId) {
    throw new Error('Azure AD is not fully defined!');
  }

  const params = new URLSearchParams(window.location.search);
  const returnLocale = params.get('returnLocale');
  if (returnLocale) {
    localStorage.setItem('locale-id', returnLocale);
  }
  const registrationSuccess = params.get('registrationSuccess') ?? '0';
  const inactivityLogout = params.get('inactivityLogout') ?? '0';
  return {
    scopes: [clientId],
    extraQueryParameters: {
      ui_locales: returnLocale ?? localStorage.getItem('locale-id') ?? getBrowserLanguage(),
      registrationSuccess,
      inactivityLogout,
    },
    // TODO: Add redirectUri to the request
    redirectUri: getAuthConfig(launchDarkly).redirectUrl,
  };
};

export const getChangePasswordRequest = (launchDarkly: TLaunchDarklySet) => {
  const { clientId } = getAdConfig(launchDarkly);
  if (!clientId) {
    throw new Error('Azure AD is not fully defined!');
  }
  return {
    scopes: [clientId],
    extraQueryParameters: {
      ui_locales: localStorage.getItem('locale-id') ?? getBrowserLanguage(),
    },
  };
};
