import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncThunkConfig } from '../../store';
import { IVerifyOtpResponse, IVerifyOtpThunkReturn } from './types';

export const verifyOtpThunk = createAsyncThunk<IVerifyOtpThunkReturn, string, AsyncThunkConfig>(
  'registration/verifyOtp',
  async (passcode, { getState }) => {
    const { launchDarkly, registration } = getState();
    const mule = launchDarkly.data['config-web-registration']?.integration?.mule?.applications?.['mule-ep-customers'];
    const muleBaseUrl = mule?.baseUrl || '';
    const consumerApp = mule?.constants?.['consumer-app'] || '';
    if (!muleBaseUrl) {
      throw new Error('Mule base url is missing in launch darkly config!');
    }
    try {
      const {
        data: { transactionId },
      } = await axios.get<IVerifyOtpResponse>(`${muleBaseUrl}/api/utils/otp?passcode=${passcode}`, {
        headers: {
          'x-consumer-app': consumerApp,
          'x-correlation-id': registration.data.otpTransactionId || '',
        },
      });

      return {
        transactionId,
      };
    } catch {
      return {
        emailError: true,
      };
    }
  },
);
