import { gql } from '@apollo/client';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { client } from '../../graphql';
import { ICreateDeviceByVinMutationParams } from './types';
import { getStartDateString } from '../utils';

const mutationName = 'createDeviceAgcoByVin';

export const createDeviceAgcoByVin = async (
  launchDarkly: TLaunchDarklySet,
  accountId: string,
  params: ICreateDeviceByVinMutationParams,
) => {
  const { vin, documents, condition, startDate } = params;
  if (!startDate) {
    throw new Error('Missing start date.');
  }

  const formattedStartDate = getStartDateString(startDate);

  const mutation = gql`
    mutation {
      ${mutationName}(
        device: {
          accountId: "${accountId}",
          vin: "${vin}",
          condition: ${condition},
          ownershipStartDate: "${formattedStartDate}",
          documents: [
            ${(documents ?? [])
              .map(
                document => `
              {
                fileName: "${document.fileName}",
                fileId: "${document.fileId}"
              }
            `,
              )
              .join(',')}
          ]
        }
      ) {
        id
      }
    }
  `;

  await client(launchDarkly).mutate({ mutation });
};
