import { gql, ApolloClient } from '@apollo/client';
import { ISearchAddress } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { TQueryResponse } from '../../../types';

export const addressSuggestions = async (
  client: ApolloClient<any>,
  launchDarkly: TLaunchDarklySet,
  addressInput: { addressLine: string; countryCode: string },
) => {
  const { data } = await client.query<TQueryResponse<'addressSuggestions', ISearchAddress[]>>({
    query: gql`
      query AddressSuggestions($addressLine: String!, $countryCode: String!) {
        addressSuggestions(addressSearch: { addressLine: $addressLine, countryCode: $countryCode }) {
          id
          street
          city
          country
          zipCode
        }
      }
    `,
    variables: {
      addressLine: addressInput.addressLine,
      countryCode: addressInput.countryCode,
    },
  });

  return data.addressSuggestions;
};
