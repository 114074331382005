export const querify = (object: Object) => {
  const entries = Object.entries(object);
  const filteredEntries = entries.filter(entry => {
    return entry[1] !== undefined;
  });
  return filteredEntries.length
    ? `?${filteredEntries
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join('&')}`
    : '';
};
