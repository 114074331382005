import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';

export const registrationPersistConfig = {
  key: 'registration',
  version: 1,
  storage: storageSession,
};

export const addMachinePersistConfig = {
  ...registrationPersistConfig,
  key: 'addMachine',
};

export const selectedAccountConfig = {
  key: 'selectedAccount',
  version: 1,
  storage,
};

export const verifyMachinePersistConfig = {
  key: 'verifyMachine',
  version: 1,
  storage,
};

export const machineDetailsPersistConfig = {
  key: 'machineDetails',
  version: 1,
  storage,
};
