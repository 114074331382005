import { createAsyncThunk } from '@reduxjs/toolkit';
import { editFleetDevice } from '../../../service/graphql/machine/editFleetDevice';
import { AsyncThunkConfig } from '../../store';
import { IMachineDetails } from '../types';

export const editMachineThunk = createAsyncThunk<
  IMachineDetails,
  { machineData: IMachineDetails; shouldSendVin: boolean },
  AsyncThunkConfig
>('machineDetails/editMachine', async ({ machineData, shouldSendVin }, { getState }) => {
  const {
    launchDarkly,
    selectedAccount: { activeAccountId },
  } = getState();

  if (!activeAccountId) {
    throw new Error('No accounts or user found.');
  }

  return editFleetDevice(launchDarkly.data, machineData, shouldSendVin);
});
