const apiKey = process.env.REACT_APP_FIREBASE_API_KEY ?? '';

export const CONFIG = {
  apiKey,
  authDomain: 'elysium-mobile-de595.firebaseapp.com',
  projectId: 'elysium-mobile-de595',
  storageBucket: 'elysium-mobile-de595.appspot.com',
  messagingSenderId: '92116669266',
  appId: '1:92116669266:web:5fdd3c8bd26e9dfd28208b',
  measurementId: 'G-FVP2ZPDMMQ',
};

export const VAPIDKEY = 'BOY0OELe8lx-46grVR7E1xLApCtTguAt93pe7HuQvmUfUKWqOtDzFw0A_f-sc3z18rHA0TQjDkQ7vNaWAX7SGl0';

export const LOCAL_HOST_NAME = 'localhost';
export const TLS_PROTOCOL = 'https:';
