import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../store/LaunchDarkly';
import { client } from '../graphql';
import { TQueryResponse } from '../../types';

type TFarmTypes = string[];

export const getFarmTypes = async (launchDarkly: TLaunchDarklySet): Promise<TFarmTypes> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'farmTypes', TFarmTypes>>({
    query: gql`
      query {
        farmTypes
      }
    `,
  });
  return data.farmTypes;
};
