import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from '../../store';
import { getFarmTypes } from '../../../service/graphql/account/getFarmTypes';

export const getFarmTypesThunk = createAsyncThunk<string[], void, AsyncThunkConfig>(
  'userAccount/getFarmTypes',
  async (_, { getState }) => {
    const { launchDarkly } = getState();
    return getFarmTypes(launchDarkly.data);
  },
);
