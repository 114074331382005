import { createAsyncThunk } from '@reduxjs/toolkit';

import { IConsents } from '@agco-onedigital/ecosystem-lib-frontend-utils/lib/utils/registration/getMappedRequestData/types';

import { IUserData } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { getUserData } from '../../../service/graphql/user/getUserData';

import { AsyncThunkConfig } from '../../store';

const initialConsents: IConsents = {
  viaPostalService: undefined,
  viaEmail: undefined,
  viaTelephone: undefined,
  viaSms: undefined,
  mobileNumber: '',
  officeNumber: '',
};

export const getUserThunk = createAsyncThunk<IUserData, void, AsyncThunkConfig>(
  'user/getUser',
  async (_, { getState }) => {
    const maxAttempts = 12;
    const pollInterval = 5000; // 5 seconds

    const pollUserData = async (attempts = 0): Promise<IUserData> => {
      try {
        const { launchDarkly } = getState();
        const userData = await getUserData(launchDarkly.data);

        //     TODO MARKETING: remove this when API ready
        const marketingConsentsJSON = localStorage.getItem('user-marketing');
        if (marketingConsentsJSON) {
          const marketingConsents: IConsents = JSON.parse(marketingConsentsJSON);
          userData.marketingConsents = marketingConsents;
        } else {
          userData.marketingConsents = initialConsents;
          localStorage.setItem('user-marketing', JSON.stringify(initialConsents));
        }

        return userData;
      } catch (error) {
        if (attempts < maxAttempts - 1) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              pollUserData(attempts + 1)
                .then(resolve)
                .catch(reject);
            }, pollInterval);
          });
        }
        // If we've reached max attempts, reject the promise
        throw error;
      }
    };

    return pollUserData();
  },
);
