import { createSlice } from '@reduxjs/toolkit';
import { commonMessages } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { Snackbar } from '@agco-onedigital/ecosystem-lib-web-components';

import { deleteMachineThunk } from '../MachineDetails/thunks';

import { getMachineListThunk } from './thunks';
import { IMachineListState } from './types';
import { processMachineList } from './utils';

const messages = commonMessages.general;

const initialState: IMachineListState = {};

const machineListSlice = createSlice({
  name: 'machineList',
  initialState,
  reducers: {
    resetMachineList: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getMachineListThunk.rejected, state => {
      state.isFailedToLoad = true;
      Snackbar.showSnack({
        title: messages.apiError,
        buttonTitle: messages.reload,
        type: 'error',
        onButtonClick: () => window.location.reload(),
      });
    });
    builder.addCase(getMachineListThunk.fulfilled, (state, action) => {
      state.data = processMachineList(action.payload);
    });
    builder.addCase(getMachineListThunk.pending, state => {
      state.isFailedToLoad = false;
    });

    builder.addCase(deleteMachineThunk.rejected, state => {
      Snackbar.showSnack({
        title: messages.apiError,
        buttonTitle: messages.reload,
        type: 'error',
        onButtonClick: () => window.location.reload(),
      });
    });
    builder.addCase(deleteMachineThunk.fulfilled, (state, action) => {
      state.data = state.data?.filter(machine => machine.id !== action.meta.arg);
    });
    builder.addCase(deleteMachineThunk.pending, state => {
      state.isFailedToLoad = false;
    });
  },
});

export const { resetMachineList } = machineListSlice.actions;

const machineListReducer = machineListSlice.reducer;

export default machineListReducer;
