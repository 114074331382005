import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { mapToRequestData } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { AsyncThunkConfig } from '../../../store';

import { IConsents, IRegisterAgcoUserResponse } from './types';

export const registerAgcoUser = createAsyncThunk<void, IConsents, AsyncThunkConfig>(
  'registration/registerAgcoUser',
  async (consents, { getState }) => {
    const { launchDarkly, registration } = getState();
    const mule = launchDarkly.data['config-web-registration']?.integration?.mule?.applications?.['mule-ep-customers'];
    const muleBaseUrl = mule?.baseUrl ?? '';
    const consumerApp = mule?.constants?.['consumer-app'] ?? '';
    if (!muleBaseUrl) {
      throw new Error('Mule base url is missing in launch darkly config!');
    }
    await axios.post<IRegisterAgcoUserResponse>(
      `${muleBaseUrl}/api/customers/applications/self-registration`,
      mapToRequestData(
        {
          data: {
            ...registration.data,
            address: {
              addressLine1: '',
              city: '',
              postalCode: '',
              country: '',
              ...(registration.data.address || {}),
            },
          },
        },
        consents,
        registration.data.locale,
      ),
      {
        headers: {
          'x-consumer-app': consumerApp,
          'x-correlation-id': registration.data.otpTransactionId || '',
          ...(registration.data.activationCodeState?.activationCode && {
            'x-ciob-access-code': registration.data.activationCodeState.activationCode,
          }),
        },
      },
    );
  },
);
