import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { AsyncThunkConfig } from '../../store';
import { TVerificationResponse } from './types';

export const verifyActivationCode = createAsyncThunk<TVerificationResponse, string, AsyncThunkConfig>(
  'registration/verifyActivationCode',
  async (activationCode, { getState }) => {
    const activationCodeUrl = getState().launchDarkly.data['config-web-registration']?.integration?.activationCodeUrl;
    try {
      await axios.get(`${activationCodeUrl}${activationCode}`);
      return {
        valid: true,
        activationCode,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status !== 404) {
          return {};
        }
      }
      return {
        valid: false,
      };
    }
  },
);
