import { gql } from '@apollo/client';
import { IUserData, UserSubscription } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { client } from '../../graphql';

export const saveUserData = async (launchDarkly: TLaunchDarklySet, user: IUserData) => {
  const {
    id = '',
    locale = '',
    salutation = '',
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    mobilePhone = '',
    address = '',
    zipCode = '',
    city = '',
    country = '',
    profilePicture = '',
    lastUsedAccountId = '',
    subscriptions,
    marketingConsentLevel = 'NOT_SET',
  } = user;

  const subscriptionsInput = subscriptions?.map((sub: UserSubscription) => ({
    brand: sub.brand,
    channelType: sub.channelType,
    type: sub.type,
    status: sub.status,
  }));

  await client(launchDarkly).mutate({
    mutation: gql`
      mutation UpdateUser($user: UserInput!) {
        update(user: $user) {
          id
          email
          salutation
          firstName
          lastName
          locale
          phone
          mobilePhone
          address
          city
          country
          zipCode
          profilePicture
          marketingConsentLevel
          subscriptions {
            brand
            channelType
            type
            status
          }
        }
      }
    `,
    variables: {
      user: {
        id,
        locale,
        salutation,
        firstName,
        lastName,
        email,
        phone,
        mobilePhone,
        address,
        zipCode,
        city,
        country,
        profilePicture,
        lastUsedAccountId,
        subscriptions: subscriptionsInput,
        marketingConsentLevel,
      },
    },
  });
};
