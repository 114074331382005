import React, { PropsWithChildren } from 'react';
import { MsalProvider as LibraryMsalProvider } from '@azure/msal-react';

import getMsalInstance from '../../../utils/msalConfiguration/msalInstance';
import { useLaunchDarkly } from '../../../store/LaunchDarkly';
import { EPreAuthRoutes } from '../../Router/types';

const MsalProvider = ({ children }: PropsWithChildren) => {
  const launchDarkly = useLaunchDarkly();
  if (window.location.pathname === EPreAuthRoutes.REGISTRATION) {
    return children as JSX.Element;
  }
  return <LibraryMsalProvider instance={getMsalInstance(launchDarkly)}>{children}</LibraryMsalProvider>;
};

export default MsalProvider;
