import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { IntlProvider, LoadingBar } from '@agco-onedigital/ecosystem-lib-web-components';

import cn from 'classnames';
import { languageOptions } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import flattenNestedStructure from '../../../utils/flattenNestedStructure';
import { useUser } from '../../../store/User';
import { useRegistration } from '../../../store/Registration';
import { getBrowserLanguage } from '../../../utils';

import SnackProvider from '../SnackProvider';
import { Container } from './styles';

const IntlProviderContainer = ({ children }: PropsWithChildren) => {
  const [messages, setMessages] = useState({});
  const registration = useRegistration();
  const user = useUser();
  const locale = user.data?.locale || registration.locale || localStorage.getItem('locale-id') || getBrowserLanguage();
  const getMessages = useCallback(async () => {
    const res = await axios.get(`/messages/${locale}.struct.json`);
    const flattened = flattenNestedStructure(res.data);
    setMessages(flattened);
  }, [locale, setMessages]);

  useEffect(() => {
    getMessages();
    document.getElementsByTagName('html')[0].setAttribute('lang', locale);
    localStorage.setItem('locale-id', locale);
  }, [locale, getMessages]);

  const snackbar = <SnackProvider />;

  const isShowLoader = user.isLoading || user.isFailedToLoad || Object.getOwnPropertyNames(messages).length === 0;

  return (
    <IntlProvider locale={languageOptions.find(lo => lo.id === locale)?.locale || 'en-gb'} messages={messages}>
      {isShowLoader && <LoadingBar />}
      <Container className={cn({ loading: isShowLoader })}>{children}</Container>
      {snackbar}
    </IntlProvider>
  );
};

export default IntlProviderContainer;
