import styled, { css } from 'styled-components';
import {
  FOOTER_HEIGHT_LAPTOP_SM,
  FOOTER_HEIGHT_TABLET_SM,
  FOOTER_HEIGHT_MOBILE,
  FOOTER_HEIGHT_TABLET_LG,
} from '../../consts/styles';

export const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  ${p =>
    p.theme.mobileLg.max(css`
      flex-direction: column;
    `)}
`;

export const ContentWrapper = styled.div`
  min-height: calc(100vh - ${FOOTER_HEIGHT_MOBILE}px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.bg.neuGray100};
  ${p =>
    p.theme.tabletSm.min(css`
      min-height: calc(100vh - ${FOOTER_HEIGHT_TABLET_SM}px);
    `)}

  ${p =>
    p.theme.tabletLg.min(css`
      min-height: calc(100vh - ${FOOTER_HEIGHT_TABLET_LG}px);
    `)}

    ${p =>
    p.theme.laptopSm.min(css`
      min-height: calc(100vh - ${FOOTER_HEIGHT_LAPTOP_SM}px);
    `)}
`;
