import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmDialog, useClickOutside } from '@agco-onedigital/ecosystem-lib-web-components';
import { commonMessages } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { INACTIVITY_WARNING_TIME_IN_MIN, WARNING_KEY } from '../../utils/msalConfiguration/inactivityLogout';
import { useLaunchDarkly } from '../../store/LaunchDarkly';

const messages = commonMessages.general;

const InactivityModal = () => {
  const launchDarkly = useLaunchDarkly();
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleInactivityEvent = () => {
      setIsOpen(true);
    };

    const checkLocalStorage = () => {
      const warningDisplayed = localStorage.getItem(WARNING_KEY);
      setIsOpen(warningDisplayed === 'true');
    };

    window.addEventListener('inactivityEvent', handleInactivityEvent);
    window.addEventListener('storage', checkLocalStorage);

    checkLocalStorage();

    return () => {
      window.removeEventListener('inactivityEvent', handleInactivityEvent);
      window.removeEventListener('storage', checkLocalStorage);
    };
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.removeItem(WARNING_KEY);
  };

  useClickOutside(modalRef, handleClose);

  if (!launchDarkly['inactivity-modal-enabled']) return null;

  return isOpen ? (
    <div ref={modalRef}>
      <ConfirmDialog
        title={formatMessage(messages.inactivityModalTitle)}
        description={formatMessage(messages.inactivityModalDescription, {
          inactiveTimeInMin: INACTIVITY_WARNING_TIME_IN_MIN,
        })}
        primaryButton={{
          label: formatMessage(messages.ok).toUpperCase(),
          onClick: handleClose,
        }}
        titleTestID="inactivity-modal"
        fullWidthButton
      />
    </div>
  ) : null;
};

export default InactivityModal;
