import { TSnackbarPosition } from '@agco-onedigital/ecosystem-lib-web-components';

export const getDefaultSnackPosition = (isOpen: boolean): TSnackbarPosition => {
  let position: TSnackbarPosition = 'topCenter';

  if (window.innerWidth < 768 || (isOpen && window.innerWidth < 1468)) {
    position = 'bottomRight';
  }

  if (isOpen && window.innerWidth > 1468) {
    position = 'topCenter';
  }

  return position;
};
