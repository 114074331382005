import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSubCategories } from '../../../service/graphql/machine/getSubCategories';

import { AsyncThunkConfig } from '../../store';
import { ISubCategory } from '../types';
import { selectCurrentAccount } from '../../User';

export const getSubCategoriesThunk = createAsyncThunk<ISubCategory[], string, AsyncThunkConfig>(
  'addMachine/getSubCategories',
  async (categoryIds, { getState }) => {
    const { launchDarkly, addMachine } = getState();
    const country = selectCurrentAccount(getState()).userAccount?.account.country;
    return getSubCategories(launchDarkly.data, addMachine.data.nonVinData?.brand?.id ?? '', categoryIds, country);
  },
);
