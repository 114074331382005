export enum EAddMachineSteps {
  VIN = 'VIN',

  MachineDetails = 'machineDetails',
  DateAndCondition = 'dateAndCondition',
  OwnershipVerification = 'ownershipVerification',

  Brand = 'brand',
  Category = 'category',
  MachineInformation = 'machineInformation',

  Summary = 'summary',
}
