import React, { PropsWithChildren, useEffect } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';

import { useAppDispatch } from '../../../store';
import { setLaunchDarkly, TLaunchDarklyUpdate, updateLaunchDarkly } from '../../../store/LaunchDarkly';

import { LAUNCHDARKLY_CLIENT_ID, CONTEXT, LDCustomLogger } from './consts';

export const launchDarklyClient = LDClient.initialize(LAUNCHDARKLY_CLIENT_ID, CONTEXT, {
  logger: LDCustomLogger,
});

const LDProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const readyFunction = () => dispatch(setLaunchDarkly(launchDarklyClient.allFlags()));
    launchDarklyClient.on('ready', readyFunction);
    return () => launchDarklyClient.off('ready', readyFunction);
  }, [dispatch]);

  useEffect(() => {
    const changeFunction = (data: TLaunchDarklyUpdate) => dispatch(updateLaunchDarkly(data));
    launchDarklyClient.on('change', changeFunction);
    return () => launchDarklyClient.off('change', changeFunction);
  }, [dispatch]);

  return children as JSX.Element;
};

export default LDProvider;
