import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCategories } from '../../../service/graphql/machine/getCategories';

import { AsyncThunkConfig } from '../../store';
import { ICategory } from '../types';
import { selectCurrentAccount } from '../../User';

export const getCategoriesThunk = createAsyncThunk<ICategory[], void, AsyncThunkConfig>(
  'addMachine/getCategories',
  async (_, { getState }) => {
    const { launchDarkly, addMachine } = getState();
    const country = selectCurrentAccount(getState()).userAccount?.account.country;
    return getCategories(launchDarkly.data, addMachine.data.nonVinData?.brand?.id ?? '', country);
  },
);
