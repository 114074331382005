import { createAsyncThunk } from '@reduxjs/toolkit';
import { getModels } from '../../../service/graphql/machine/getModels';

import { AsyncThunkConfig } from '../../store';
import { IModel } from '../types';
import { selectCurrentAccount } from '../../User';

export const getModelsThunk = createAsyncThunk<IModel[], string, AsyncThunkConfig>(
  'addMachine/getModels',
  async (serieId, { getState }) => {
    const { launchDarkly } = getState();
    const country = selectCurrentAccount(getState()).userAccount?.account.country;
    return getModels(launchDarkly.data, serieId, country);
  },
);
