import { gql } from '@apollo/client';
import { TQueryResponse } from '../../types';
import { TLaunchDarklySet } from '../../../store/LaunchDarkly';
import { client } from '../graphql';

export const getBusinessTypes = async (launchDarkly: TLaunchDarklySet): Promise<string[]> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'businessTypes', string[]>>({
    query: gql`
      query {
        businessTypes
      }
    `,
  });
  return data.businessTypes;
};
