interface IMessages {
  [key: string]: string;
}

type INestedMessages =
  | {
      [key: string]: INestedMessages;
    }
  | string;

function flattenNestedStructure(obj: INestedMessages, path: string[] = []): IMessages {
  let result: IMessages = {};

  for (const [key, value] of Object.entries(obj)) {
    const newPath = [...path, key];

    if (typeof value === 'object' && value !== null) {
      const { _self, ...rest } = value;

      if (_self) {
        result[newPath.join('.')] = _self as string;
      }

      result = { ...result, ...flattenNestedStructure(rest, newPath) };
    } else {
      result[newPath.join('.')] = value;
    }
  }

  return result;
}

export default flattenNestedStructure;
