import fetch from 'cross-fetch';
import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createClient } from 'graphql-ws';
import { createUploadLink } from 'apollo-upload-client';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import axios from 'axios';

import { getAuthDataLD } from '../../utils/msalConfiguration/utils';
import { TLaunchDarklySet } from '../../store/LaunchDarkly';

const authLink = (launchDarkly: TLaunchDarklySet) =>
  setContext(async (_operation, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = (await getAuthDataLD(launchDarkly)).accessToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const getGraphqlBaseUrl = (launchDarkly: TLaunchDarklySet, isWs?: boolean) => {
  const graphql = launchDarkly['config-web-app']?.ecosystem?.graphql;
  if (!graphql) {
    throw new Error('Graphql is not defined!');
  }
  const { host, pathPrefix, protocol } = graphql;
  if (!host || pathPrefix === undefined || !protocol) {
    throw new Error('Graphql is not defined!');
  }
  return `${isWs ? 'wws' : protocol}://${host}${pathPrefix}`;
};

export const httpLink = (launchDarkly: TLaunchDarklySet) =>
  createHttpLink({
    uri: `${getGraphqlBaseUrl(launchDarkly)}/graphql`,
    fetch,
  });

export const wsLink = (launchDarkly: TLaunchDarklySet) =>
  createClient({
    url: `${getGraphqlBaseUrl(launchDarkly, true)}/subscriptions`,
  });

export interface IApolloClientOptions {
  withProgress?: boolean;
}

// eslint-disable-next-line no-undef
interface IRequestInitWithProgress extends RequestInit {
  onUploadProgress: (progress: ProgressEvent) => void;
}

export const httpLinkWithUpload = (launchDarkly: TLaunchDarklySet) =>
  createUploadLink({
    uri: `${getGraphqlBaseUrl(launchDarkly)}/graphql`,
    fetch: buildAxiosFetch<IRequestInitWithProgress>(axios, (config, _, init) => ({
      ...config,
      onUploadProgress: init?.onUploadProgress,
    })),
  });

export const client = (launchDarkly: TLaunchDarklySet, options?: IApolloClientOptions) =>
  new ApolloClient({
    link: authLink(launchDarkly).concat(
      options?.withProgress ? httpLinkWithUpload(launchDarkly) : httpLink(launchDarkly),
    ),
    cache: new InMemoryCache(),
    defaultOptions,
  });
