import { gql } from '@apollo/client';
import { IModelVariant } from '../../../../store/AddMachine';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getModelVariants = async (
  launchDarkly: TLaunchDarklySet,
  modelIds: string,
  country?: string,
): Promise<IModelVariant[]> => {
  const { data } = await client(launchDarkly).query<TQueryResponse<'deviceModelVariants', IModelVariant[]>>({
    query: gql`
      query {
        deviceModelVariants(filter: { modelIds: "${modelIds}", countries: ${JSON.stringify(
      country ? [country] : [],
    )} }) {
          deviceType
          id
          model {
            id
            name
          }
          name
          }
      }
    `,
  });
  return data.deviceModelVariants;
};
