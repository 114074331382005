import React, { PropsWithChildren } from 'react';
import { ThemeProvider as LibraryThemeProvider } from '@agco-onedigital/ecosystem-lib-web-components';
import { TBrand } from '@agco-onedigital/ecosystem-lib-brand-styles';

import { useLaunchDarkly } from '../../../store/LaunchDarkly';

import HeadProvider from '../HeadProvider';

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const launchDarkly = useLaunchDarkly();
  const config = launchDarkly['config-web-app'];

  if (!config) {
    return null;
  }

  const dynatraceUrl = config?.dynatrace?.url;

  const brand =
    process.env.NODE_ENV === 'development'
      ? (process.env.REACT_APP_NAME as TBrand)
      : config.ecosystem?.brand || 'valtra';

  return (
    <LibraryThemeProvider brand={brand}>
      <HeadProvider dynatraceUrl={dynatraceUrl} brand={brand} />
      {children}
    </LibraryThemeProvider>
  );
};

export default ThemeProvider;
