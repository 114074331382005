import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TLaunchDarklyState, TLaunchDarklyUpdate, TLaunchDarklySet } from './types';

const initialState: TLaunchDarklyState = {
  data: {},
};

const launchDarklySlice = createSlice({
  name: 'launchDarkly',
  initialState,
  reducers: {
    init: state => {
      state.data = {};
      window.SHARED_MODULE_DATA.launchDarklySet = state.data;
    },
    setLaunchDarkly: (state, action: PayloadAction<TLaunchDarklySet>) => {
      state.data = { ...state.data, ...action.payload };
      window.SHARED_MODULE_DATA.launchDarklySet = state.data;
    },
    updateLaunchDarkly: (state, action: PayloadAction<TLaunchDarklyUpdate>) => {
      let data = { ...state.data };
      const payloadKeys = Object.keys(action.payload) as [keyof TLaunchDarklyUpdate];

      for (const payloadKey of payloadKeys) {
        data = { ...data, [payloadKey]: action.payload[payloadKey]!.current };
      }

      state.data = data;
      window.SHARED_MODULE_DATA.launchDarklySet = state.data;
    },
  },
});

export const { init, setLaunchDarkly, updateLaunchDarkly } = launchDarklySlice.actions;

const launchDarklyReducer = launchDarklySlice.reducer;

export default launchDarklyReducer;
