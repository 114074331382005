import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppDispatch } from '../../store';
import { useLaunchDarkly } from '../../store/LaunchDarkly';
import { setActiveAccount } from '../../store/UserAccount';
import { getUserThunk, saveUserThunk, useUser } from '../../store/User';

import { getHostName, LAUNCHDARKLY_APP_TYPE } from '../Providers/LDProvider/consts';
import { launchDarklyClient } from '../Providers/LDProvider/LDProvider';

import { EPreAuthRoutes } from './types';

const Authenticated = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const { data: user } = useUser();
  const [isGetUserDataAvailable, setIsGetUserDataAvailable] = useState(false);
  const launchDarkly = useLaunchDarkly();
  const dispatch = useAppDispatch();
  const [hasJustRegistered, setHasJustRegistered] = useState(true);

  useEffect(() => {
    if (!user) {
      dispatch(getUserThunk());
      return;
    }
    const { id, firstName, lastName, lastUsedAccount } = user;
    const firstAccount = user.accounts[0].account;
    if (!lastUsedAccount?.id && hasJustRegistered) {
      dispatch(setActiveAccount(firstAccount.id));
      dispatch(saveUserThunk({ lastUsedAccountId: firstAccount.id }));
      setHasJustRegistered(false);
    }
    const activeAccount = user.accounts.find(a => a.account.id === user.lastUsedAccount?.id);
    const businessCountry = activeAccount?.account?.country ?? firstAccount.country;
    launchDarklyClient.identify({
      kind: 'user',
      key: id,
      hostName: getHostName(),
      country: businessCountry,
      appId: '',
      appType: LAUNCHDARKLY_APP_TYPE,
      firstName,
      lastName,
      accountIds: user.accounts.map(account => account.account.id),
      email: user.email,
    });
    window.SHARED_MODULE_DATA.getUserData = () => user;
    setIsGetUserDataAvailable(true);
  }, [user, launchDarkly, dispatch, hasJustRegistered]);

  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to={EPreAuthRoutes.HOME} state={{ from: location }} replace />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>{isGetUserDataAvailable && children}</AuthenticatedTemplate>
    </>
  );
};

export default Authenticated;
