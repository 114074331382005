import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { TLaunchDarklySet } from '../../../store/LaunchDarkly';
import { defaultOptions, httpLink, httpLinkWithUpload, IApolloClientOptions } from '../graphql';

const authLink = () =>
  setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const correlationId = sessionStorage.getItem('transactionId');
    const passcode = sessionStorage.getItem('pinPassCode');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'x-agco-correlation-id': correlationId ?? '',
        'x-agco-passcode': passcode ?? '',
      },
    };
  });

export const registrationClient = (launchDarkly: TLaunchDarklySet, options?: IApolloClientOptions) =>
  new ApolloClient({
    link: authLink().concat(options?.withProgress ? httpLinkWithUpload(launchDarkly) : httpLink(launchDarkly)),
    cache: new InMemoryCache(),
    defaultOptions,
  });
