import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@agco-onedigital/ecosystem-lib-web-components';
import { EAuthRoutes, useSidebarProps, ISidebarProps } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { useAppDispatch } from '../../store';
import { setActiveAccount, useUserAccount } from '../../store/UserAccount';
import { saveUserThunk, useUser } from '../../store/User';
import { useLaunchDarkly } from '../../store/LaunchDarkly';
import { resetMachineList } from '../../store/MachineList';
import { handleLogoutRedirect } from '../../utils/msalConfiguration/utils';
import { useDtrumUserSetup } from '../../utils/dtrumUserSetup';

import { getMachineManagementEnabled, useServiceInformationLinks } from './utils';

const SidebarContainer = () => {
  useDtrumUserSetup();
  const dispatch = useAppDispatch();
  const launchDarkly = useLaunchDarkly();
  const { name } = useTheme();
  const { activeAccountId } = useUserAccount();
  const { data } = useUser();
  const accounts = (data?.accounts || []).map(account => ({
    id: account.account.id,
    label: account.account.name ?? '',
  }));

  const isUpdatedSidebarEnabled = launchDarkly['updated-sidebar-enabled'] ?? false;

  const handleSelectAccount = (newAccountId: string) => {
    dispatch(setActiveAccount(newAccountId));
    dispatch(saveUserThunk({ lastUsedAccountId: newAccountId }));
    dispatch(resetMachineList());
  };

  const handleLogOut = () => {
    dispatch(setActiveAccount(''));
    handleLogoutRedirect(launchDarkly);
  };

  const externalLinks = useServiceInformationLinks();

  const props: ISidebarProps = {
    isMachineManagementEnabled: getMachineManagementEnabled(launchDarkly, name),
    isDataAndPrivacyPageEnabled: launchDarkly['data-privacy-page-visibility'] ?? false,
    accountItems: accounts,
    activeAccountId,
    onAccountSelect: handleSelectAccount,
    handleLogOut,
    user: {
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
    },
    externalLinks,
  };

  const { topItems, bottomItems, userData } = useSidebarProps(props, isUpdatedSidebarEnabled);

  return <Sidebar topItems={topItems} bottomItems={bottomItems} logoPath={EAuthRoutes.DASHBOARD} user={userData} />;
};

export default SidebarContainer;
