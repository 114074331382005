import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from '../../store';
import { getBusinessTypes } from '../../../service/graphql/account/getBusinessTypes';

export const getBusinessTypesThunk = createAsyncThunk<string[], void, AsyncThunkConfig>(
  'userAccount/getBusinessTypes',
  async (_, { getState }) => {
    const { launchDarkly } = getState();
    return getBusinessTypes(launchDarkly.data);
  },
);
